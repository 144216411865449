/* ----- CSS reset ----- */
*,
*::before,
*::after {
  box-sizing: border-box;
}

input,
button,
textarea,
select {
  font: inherit;
}

button,
select {
  text-transform: none;
}

/* ----- Global styles ----- */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-family: var(--mantine-font-family);
  font-size: calc(1rem * 1);
  font-size: var(--mantine-font-size-md);
  line-height: 1.55;
  line-height: var(--mantine-line-height);
  background-color: var(--mantine-color-body);
  color: var(--mantine-color-text);

  -webkit-font-smoothing: antialiased;

  -webkit-font-smoothing: var(--mantine-webkit-font-smoothing);
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: var(--mantine-moz-font-smoothing);
}
@media screen and (max-device-width: 31.25em) {
body {
    -webkit-text-size-adjust: 100%
}
  }

@media (prefers-reduced-motion: reduce) {
    [data-respect-reduced-motion] [data-reduce-motion] {
      transition: none;
      animation: none;
    }
  }

[data-mantine-color-scheme='light'] .mantine-light-hidden {
    display: none;
}

[data-mantine-color-scheme='dark'] .mantine-dark-hidden {
    display: none;
}

.mantine-focus-auto:focus-visible {
    outline: 2px solid var(--mantine-primary-color-filled);
    outline-offset: 2px;
  }

.mantine-focus-always:focus {
    outline: 2px solid var(--mantine-primary-color-filled);
    outline-offset: 2px;
  }

.mantine-focus-never:focus {
    outline: none;
  }

.mantine-active:active {
    transform: translateY(calc(0.0625rem * 1));
    transform: translateY(calc(0.0625rem * var(--mantine-scale)));
  }

fieldset:disabled .mantine-active:active {
    transform: none;
  }

:where([dir="rtl"]) .mantine-rotate-rtl {
    transform: rotate(180deg);
}

/* ----- Default CSS variables ----- */

:root {
  --csstools-color-scheme--dark:  ;
  color-scheme: light dark;
  color-scheme: var(--mantine-color-scheme);

  --mantine-z-index-app: 100;
  --mantine-z-index-modal: 200;
  --mantine-z-index-popover: 300;
  --mantine-z-index-overlay: 400;
  --mantine-z-index-max: 9999;

  --mantine-scale: 1;
  --mantine-cursor-type: default;
  --mantine-webkit-font-smoothing: antialiased;
  --mantine-color-scheme: light dark;
  --mantine-moz-font-smoothing: grayscale;
  --mantine-color-white: #fff;
  --mantine-color-black: #000;
  --mantine-line-height: 1.55;
  --mantine-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
  --mantine-font-family-monospace: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
  --mantine-font-family-headings: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  --mantine-heading-font-weight: 700;
  --mantine-radius-default: calc(0.25rem * var(--mantine-scale));
  --mantine-primary-color-0: var(--mantine-color-blue-0);
  --mantine-primary-color-1: var(--mantine-color-blue-1);
  --mantine-primary-color-2: var(--mantine-color-blue-2);
  --mantine-primary-color-3: var(--mantine-color-blue-3);
  --mantine-primary-color-4: var(--mantine-color-blue-4);
  --mantine-primary-color-5: var(--mantine-color-blue-5);
  --mantine-primary-color-6: var(--mantine-color-blue-6);
  --mantine-primary-color-7: var(--mantine-color-blue-7);
  --mantine-primary-color-8: var(--mantine-color-blue-8);
  --mantine-primary-color-9: var(--mantine-color-blue-9);
  --mantine-primary-color-filled: var(--mantine-color-blue-filled);
  --mantine-primary-color-filled-hover: var(--mantine-color-blue-filled-hover);
  --mantine-primary-color-light: var(--mantine-color-blue-light);
  --mantine-primary-color-light-hover: var(--mantine-color-blue-light-hover);
  --mantine-primary-color-light-color: var(--mantine-color-blue-light-color);
  --mantine-breakpoint-xs: 36em;
  --mantine-breakpoint-sm: 48em;
  --mantine-breakpoint-md: 62em;
  --mantine-breakpoint-lg: 75em;
  --mantine-breakpoint-xl: 88em;
  --mantine-spacing-xs: calc(0.625rem * var(--mantine-scale));
  --mantine-spacing-sm: calc(0.75rem * var(--mantine-scale));
  --mantine-spacing-md: calc(1rem * var(--mantine-scale));
  --mantine-spacing-lg: calc(1.25rem * var(--mantine-scale));
  --mantine-spacing-xl: calc(2rem * var(--mantine-scale));
  --mantine-font-size-xs: calc(0.75rem * var(--mantine-scale));
  --mantine-font-size-sm: calc(0.875rem * var(--mantine-scale));
  --mantine-font-size-md: calc(1rem * var(--mantine-scale));
  --mantine-font-size-lg: calc(1.125rem * var(--mantine-scale));
  --mantine-font-size-xl: calc(1.25rem * var(--mantine-scale));
  --mantine-line-height-xs: 1.4;
  --mantine-line-height-sm: 1.45;
  --mantine-line-height-md: 1.55;
  --mantine-line-height-lg: 1.6;
  --mantine-line-height-xl: 1.65;
  --mantine-shadow-xs: 0 calc(0.0625rem * var(--mantine-scale))
      calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
    0 calc(0.0625rem * var(--mantine-scale)) calc(0.125rem * var(--mantine-scale))
      rgba(0, 0, 0, 0.1);
  --mantine-shadow-sm: 0 calc(0.0625rem * var(--mantine-scale))
      calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 0 calc(0.625rem * var(--mantine-scale))
      calc(0.9375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)),
    rgba(0, 0, 0, 0.04) 0 calc(0.4375rem * var(--mantine-scale))
      calc(0.4375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale));
  --mantine-shadow-md: 0 calc(0.0625rem * var(--mantine-scale))
      calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 0 calc(1.25rem * var(--mantine-scale))
      calc(1.5625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)),
    rgba(0, 0, 0, 0.04) 0 calc(0.625rem * var(--mantine-scale))
      calc(0.625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale));
  --mantine-shadow-lg: 0 calc(0.0625rem * var(--mantine-scale))
      calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 0 calc(1.75rem * var(--mantine-scale))
      calc(1.4375rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)),
    rgba(0, 0, 0, 0.04) 0 calc(0.75rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale))
      calc(-0.4375rem * var(--mantine-scale));
  --mantine-shadow-xl: 0 calc(0.0625rem * var(--mantine-scale))
      calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 0 calc(2.25rem * var(--mantine-scale)) calc(1.75rem * var(--mantine-scale))
      calc(-0.4375rem * var(--mantine-scale)),
    rgba(0, 0, 0, 0.04) 0 calc(1.0625rem * var(--mantine-scale))
      calc(1.0625rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale));
  --mantine-radius-xs: calc(0.125rem * var(--mantine-scale));
  --mantine-radius-sm: calc(0.25rem * var(--mantine-scale));
  --mantine-radius-md: calc(0.5rem * var(--mantine-scale));
  --mantine-radius-lg: calc(1rem * var(--mantine-scale));
  --mantine-radius-xl: calc(2rem * var(--mantine-scale));
  --mantine-color-dark-0: #c9c9c9;
  --mantine-color-dark-1: #b8b8b8;
  --mantine-color-dark-2: #828282;
  --mantine-color-dark-3: #696969;
  --mantine-color-dark-4: #424242;
  --mantine-color-dark-5: #3b3b3b;
  --mantine-color-dark-6: #2e2e2e;
  --mantine-color-dark-7: #242424;
  --mantine-color-dark-8: #1f1f1f;
  --mantine-color-dark-9: #141414;
  --mantine-color-gray-0: #f8f9fa;
  --mantine-color-gray-1: #f1f3f5;
  --mantine-color-gray-2: #e9ecef;
  --mantine-color-gray-3: #dee2e6;
  --mantine-color-gray-4: #ced4da;
  --mantine-color-gray-5: #adb5bd;
  --mantine-color-gray-6: #868e96;
  --mantine-color-gray-7: #495057;
  --mantine-color-gray-8: #343a40;
  --mantine-color-gray-9: #212529;
  --mantine-color-red-0: #fff5f5;
  --mantine-color-red-1: #ffe3e3;
  --mantine-color-red-2: #ffc9c9;
  --mantine-color-red-3: #ffa8a8;
  --mantine-color-red-4: #ff8787;
  --mantine-color-red-5: #ff6b6b;
  --mantine-color-red-6: #fa5252;
  --mantine-color-red-7: #f03e3e;
  --mantine-color-red-8: #e03131;
  --mantine-color-red-9: #c92a2a;
  --mantine-color-pink-0: #fff0f6;
  --mantine-color-pink-1: #ffdeeb;
  --mantine-color-pink-2: #fcc2d7;
  --mantine-color-pink-3: #faa2c1;
  --mantine-color-pink-4: #f783ac;
  --mantine-color-pink-5: #f06595;
  --mantine-color-pink-6: #e64980;
  --mantine-color-pink-7: #d6336c;
  --mantine-color-pink-8: #c2255c;
  --mantine-color-pink-9: #a61e4d;
  --mantine-color-grape-0: #f8f0fc;
  --mantine-color-grape-1: #f3d9fa;
  --mantine-color-grape-2: #eebefa;
  --mantine-color-grape-3: #e599f7;
  --mantine-color-grape-4: #da77f2;
  --mantine-color-grape-5: #cc5de8;
  --mantine-color-grape-6: #be4bdb;
  --mantine-color-grape-7: #ae3ec9;
  --mantine-color-grape-8: #9c36b5;
  --mantine-color-grape-9: #862e9c;
  --mantine-color-violet-0: #f3f0ff;
  --mantine-color-violet-1: #e5dbff;
  --mantine-color-violet-2: #d0bfff;
  --mantine-color-violet-3: #b197fc;
  --mantine-color-violet-4: #9775fa;
  --mantine-color-violet-5: #845ef7;
  --mantine-color-violet-6: #7950f2;
  --mantine-color-violet-7: #7048e8;
  --mantine-color-violet-8: #6741d9;
  --mantine-color-violet-9: #5f3dc4;
  --mantine-color-indigo-0: #edf2ff;
  --mantine-color-indigo-1: #dbe4ff;
  --mantine-color-indigo-2: #bac8ff;
  --mantine-color-indigo-3: #91a7ff;
  --mantine-color-indigo-4: #748ffc;
  --mantine-color-indigo-5: #5c7cfa;
  --mantine-color-indigo-6: #4c6ef5;
  --mantine-color-indigo-7: #4263eb;
  --mantine-color-indigo-8: #3b5bdb;
  --mantine-color-indigo-9: #364fc7;
  --mantine-color-blue-0: #e7f5ff;
  --mantine-color-blue-1: #d0ebff;
  --mantine-color-blue-2: #a5d8ff;
  --mantine-color-blue-3: #74c0fc;
  --mantine-color-blue-4: #4dabf7;
  --mantine-color-blue-5: #339af0;
  --mantine-color-blue-6: #228be6;
  --mantine-color-blue-7: #1c7ed6;
  --mantine-color-blue-8: #1971c2;
  --mantine-color-blue-9: #1864ab;
  --mantine-color-cyan-0: #e3fafc;
  --mantine-color-cyan-1: #c5f6fa;
  --mantine-color-cyan-2: #99e9f2;
  --mantine-color-cyan-3: #66d9e8;
  --mantine-color-cyan-4: #3bc9db;
  --mantine-color-cyan-5: #22b8cf;
  --mantine-color-cyan-6: #15aabf;
  --mantine-color-cyan-7: #1098ad;
  --mantine-color-cyan-8: #0c8599;
  --mantine-color-cyan-9: #0b7285;
  --mantine-color-teal-0: #e6fcf5;
  --mantine-color-teal-1: #c3fae8;
  --mantine-color-teal-2: #96f2d7;
  --mantine-color-teal-3: #63e6be;
  --mantine-color-teal-4: #38d9a9;
  --mantine-color-teal-5: #20c997;
  --mantine-color-teal-6: #12b886;
  --mantine-color-teal-7: #0ca678;
  --mantine-color-teal-8: #099268;
  --mantine-color-teal-9: #087f5b;
  --mantine-color-green-0: #ebfbee;
  --mantine-color-green-1: #d3f9d8;
  --mantine-color-green-2: #b2f2bb;
  --mantine-color-green-3: #8ce99a;
  --mantine-color-green-4: #69db7c;
  --mantine-color-green-5: #51cf66;
  --mantine-color-green-6: #40c057;
  --mantine-color-green-7: #37b24d;
  --mantine-color-green-8: #2f9e44;
  --mantine-color-green-9: #2b8a3e;
  --mantine-color-lime-0: #f4fce3;
  --mantine-color-lime-1: #e9fac8;
  --mantine-color-lime-2: #d8f5a2;
  --mantine-color-lime-3: #c0eb75;
  --mantine-color-lime-4: #a9e34b;
  --mantine-color-lime-5: #94d82d;
  --mantine-color-lime-6: #82c91e;
  --mantine-color-lime-7: #74b816;
  --mantine-color-lime-8: #66a80f;
  --mantine-color-lime-9: #5c940d;
  --mantine-color-yellow-0: #fff9db;
  --mantine-color-yellow-1: #fff3bf;
  --mantine-color-yellow-2: #ffec99;
  --mantine-color-yellow-3: #ffe066;
  --mantine-color-yellow-4: #ffd43b;
  --mantine-color-yellow-5: #fcc419;
  --mantine-color-yellow-6: #fab005;
  --mantine-color-yellow-7: #f59f00;
  --mantine-color-yellow-8: #f08c00;
  --mantine-color-yellow-9: #e67700;
  --mantine-color-orange-0: #fff4e6;
  --mantine-color-orange-1: #ffe8cc;
  --mantine-color-orange-2: #ffd8a8;
  --mantine-color-orange-3: #ffc078;
  --mantine-color-orange-4: #ffa94d;
  --mantine-color-orange-5: #ff922b;
  --mantine-color-orange-6: #fd7e14;
  --mantine-color-orange-7: #f76707;
  --mantine-color-orange-8: #e8590c;
  --mantine-color-orange-9: #d9480f;
  --mantine-h1-font-size: calc(2.125rem * var(--mantine-scale));
  --mantine-h1-line-height: 1.3;
  --mantine-h1-font-weight: 700;
  --mantine-h2-font-size: calc(1.625rem * var(--mantine-scale));
  --mantine-h2-line-height: 1.35;
  --mantine-h2-font-weight: 700;
  --mantine-h3-font-size: calc(1.375rem * var(--mantine-scale));
  --mantine-h3-line-height: 1.4;
  --mantine-h3-font-weight: 700;
  --mantine-h4-font-size: calc(1.125rem * var(--mantine-scale));
  --mantine-h4-line-height: 1.45;
  --mantine-h4-font-weight: 700;
  --mantine-h5-font-size: calc(1rem * var(--mantine-scale));
  --mantine-h5-line-height: 1.5;
  --mantine-h5-font-weight: 700;
  --mantine-h6-font-size: calc(0.875rem * var(--mantine-scale));
  --mantine-h6-line-height: 1.5;
  --mantine-h6-font-weight: 700;
}

@media (prefers-color-scheme: dark) {

:root {
  --csstools-color-scheme--dark: initial;
}
}

:root[data-mantine-color-scheme='dark'] {
  --mantine-color-scheme: dark;
  --mantine-primary-color-contrast: var(--mantine-color-white);
  --mantine-color-bright: var(--mantine-color-white);
  --mantine-color-text: var(--mantine-color-dark-0);
  --mantine-color-body: var(--mantine-color-dark-7);
  --mantine-color-error: var(--mantine-color-red-8);
  --mantine-color-placeholder: var(--mantine-color-dark-3);
  --mantine-color-anchor: var(--mantine-color-blue-4);
  --mantine-color-default: var(--mantine-color-dark-6);
  --mantine-color-default-hover: var(--mantine-color-dark-5);
  --mantine-color-default-color: var(--mantine-color-white);
  --mantine-color-default-border: var(--mantine-color-dark-4);
  --mantine-color-dimmed: var(--mantine-color-dark-2);
  --mantine-color-dark-text: var(--mantine-color-dark-4);
  --mantine-color-dark-filled: var(--mantine-color-dark-8);
  --mantine-color-dark-filled-hover: var(--mantine-color-dark-7);
  --mantine-color-dark-light: rgba(36, 36, 36, 0.15);
  --mantine-color-dark-light-hover: rgba(36, 36, 36, 0.2);
  --mantine-color-dark-light-color: var(--mantine-color-dark-3);
  --mantine-color-dark-outline: var(--mantine-color-dark-4);
  --mantine-color-dark-outline-hover: rgba(36, 36, 36, 0.05);
  --mantine-color-gray-text: var(--mantine-color-gray-4);
  --mantine-color-gray-filled: var(--mantine-color-gray-8);
  --mantine-color-gray-filled-hover: var(--mantine-color-gray-9);
  --mantine-color-gray-light: rgba(134, 142, 150, 0.15);
  --mantine-color-gray-light-hover: rgba(134, 142, 150, 0.2);
  --mantine-color-gray-light-color: var(--mantine-color-gray-3);
  --mantine-color-gray-outline: var(--mantine-color-gray-4);
  --mantine-color-gray-outline-hover: rgba(206, 212, 218, 0.05);
  --mantine-color-red-text: var(--mantine-color-red-4);
  --mantine-color-red-filled: var(--mantine-color-red-8);
  --mantine-color-red-filled-hover: var(--mantine-color-red-9);
  --mantine-color-red-light: rgba(250, 82, 82, 0.15);
  --mantine-color-red-light-hover: rgba(250, 82, 82, 0.2);
  --mantine-color-red-light-color: var(--mantine-color-red-3);
  --mantine-color-red-outline: var(--mantine-color-red-4);
  --mantine-color-red-outline-hover: rgba(255, 135, 135, 0.05);
  --mantine-color-pink-text: var(--mantine-color-pink-4);
  --mantine-color-pink-filled: var(--mantine-color-pink-8);
  --mantine-color-pink-filled-hover: var(--mantine-color-pink-9);
  --mantine-color-pink-light: rgba(230, 73, 128, 0.15);
  --mantine-color-pink-light-hover: rgba(230, 73, 128, 0.2);
  --mantine-color-pink-light-color: var(--mantine-color-pink-3);
  --mantine-color-pink-outline: var(--mantine-color-pink-4);
  --mantine-color-pink-outline-hover: rgba(247, 131, 172, 0.05);
  --mantine-color-grape-text: var(--mantine-color-grape-4);
  --mantine-color-grape-filled: var(--mantine-color-grape-8);
  --mantine-color-grape-filled-hover: var(--mantine-color-grape-9);
  --mantine-color-grape-light: rgba(190, 75, 219, 0.15);
  --mantine-color-grape-light-hover: rgba(190, 75, 219, 0.2);
  --mantine-color-grape-light-color: var(--mantine-color-grape-3);
  --mantine-color-grape-outline: var(--mantine-color-grape-4);
  --mantine-color-grape-outline-hover: rgba(218, 119, 242, 0.05);
  --mantine-color-violet-text: var(--mantine-color-violet-4);
  --mantine-color-violet-filled: var(--mantine-color-violet-8);
  --mantine-color-violet-filled-hover: var(--mantine-color-violet-9);
  --mantine-color-violet-light: rgba(121, 80, 242, 0.15);
  --mantine-color-violet-light-hover: rgba(121, 80, 242, 0.2);
  --mantine-color-violet-light-color: var(--mantine-color-violet-3);
  --mantine-color-violet-outline: var(--mantine-color-violet-4);
  --mantine-color-violet-outline-hover: rgba(151, 117, 250, 0.05);
  --mantine-color-indigo-text: var(--mantine-color-indigo-4);
  --mantine-color-indigo-filled: var(--mantine-color-indigo-8);
  --mantine-color-indigo-filled-hover: var(--mantine-color-indigo-9);
  --mantine-color-indigo-light: rgba(76, 110, 245, 0.15);
  --mantine-color-indigo-light-hover: rgba(76, 110, 245, 0.2);
  --mantine-color-indigo-light-color: var(--mantine-color-indigo-3);
  --mantine-color-indigo-outline: var(--mantine-color-indigo-4);
  --mantine-color-indigo-outline-hover: rgba(116, 143, 252, 0.05);
  --mantine-color-blue-text: var(--mantine-color-blue-4);
  --mantine-color-blue-filled: var(--mantine-color-blue-8);
  --mantine-color-blue-filled-hover: var(--mantine-color-blue-9);
  --mantine-color-blue-light: rgba(34, 139, 230, 0.15);
  --mantine-color-blue-light-hover: rgba(34, 139, 230, 0.2);
  --mantine-color-blue-light-color: var(--mantine-color-blue-3);
  --mantine-color-blue-outline: var(--mantine-color-blue-4);
  --mantine-color-blue-outline-hover: rgba(77, 171, 247, 0.05);
  --mantine-color-cyan-text: var(--mantine-color-cyan-4);
  --mantine-color-cyan-filled: var(--mantine-color-cyan-8);
  --mantine-color-cyan-filled-hover: var(--mantine-color-cyan-9);
  --mantine-color-cyan-light: rgba(21, 170, 191, 0.15);
  --mantine-color-cyan-light-hover: rgba(21, 170, 191, 0.2);
  --mantine-color-cyan-light-color: var(--mantine-color-cyan-3);
  --mantine-color-cyan-outline: var(--mantine-color-cyan-4);
  --mantine-color-cyan-outline-hover: rgba(59, 201, 219, 0.05);
  --mantine-color-teal-text: var(--mantine-color-teal-4);
  --mantine-color-teal-filled: var(--mantine-color-teal-8);
  --mantine-color-teal-filled-hover: var(--mantine-color-teal-9);
  --mantine-color-teal-light: rgba(18, 184, 134, 0.15);
  --mantine-color-teal-light-hover: rgba(18, 184, 134, 0.2);
  --mantine-color-teal-light-color: var(--mantine-color-teal-3);
  --mantine-color-teal-outline: var(--mantine-color-teal-4);
  --mantine-color-teal-outline-hover: rgba(56, 217, 169, 0.05);
  --mantine-color-green-text: var(--mantine-color-green-4);
  --mantine-color-green-filled: var(--mantine-color-green-8);
  --mantine-color-green-filled-hover: var(--mantine-color-green-9);
  --mantine-color-green-light: rgba(64, 192, 87, 0.15);
  --mantine-color-green-light-hover: rgba(64, 192, 87, 0.2);
  --mantine-color-green-light-color: var(--mantine-color-green-3);
  --mantine-color-green-outline: var(--mantine-color-green-4);
  --mantine-color-green-outline-hover: rgba(105, 219, 124, 0.05);
  --mantine-color-lime-text: var(--mantine-color-lime-4);
  --mantine-color-lime-filled: var(--mantine-color-lime-8);
  --mantine-color-lime-filled-hover: var(--mantine-color-lime-9);
  --mantine-color-lime-light: rgba(130, 201, 30, 0.15);
  --mantine-color-lime-light-hover: rgba(130, 201, 30, 0.2);
  --mantine-color-lime-light-color: var(--mantine-color-lime-3);
  --mantine-color-lime-outline: var(--mantine-color-lime-4);
  --mantine-color-lime-outline-hover: rgba(169, 227, 75, 0.05);
  --mantine-color-yellow-text: var(--mantine-color-yellow-4);
  --mantine-color-yellow-filled: var(--mantine-color-yellow-8);
  --mantine-color-yellow-filled-hover: var(--mantine-color-yellow-9);
  --mantine-color-yellow-light: rgba(250, 176, 5, 0.15);
  --mantine-color-yellow-light-hover: rgba(250, 176, 5, 0.2);
  --mantine-color-yellow-light-color: var(--mantine-color-yellow-3);
  --mantine-color-yellow-outline: var(--mantine-color-yellow-4);
  --mantine-color-yellow-outline-hover: rgba(255, 212, 59, 0.05);
  --mantine-color-orange-text: var(--mantine-color-orange-4);
  --mantine-color-orange-filled: var(--mantine-color-orange-8);
  --mantine-color-orange-filled-hover: var(--mantine-color-orange-9);
  --mantine-color-orange-light: rgba(253, 126, 20, 0.15);
  --mantine-color-orange-light-hover: rgba(253, 126, 20, 0.2);
  --mantine-color-orange-light-color: var(--mantine-color-orange-3);
  --mantine-color-orange-outline: var(--mantine-color-orange-4);
  --mantine-color-orange-outline-hover: rgba(255, 169, 77, 0.05);
}

:root[data-mantine-color-scheme='light'] {
  --mantine-color-scheme: light;
  --mantine-color-bright: var(--mantine-color-black);
  --mantine-color-text: var(--mantine-color-black);
  --mantine-color-body: var(--mantine-color-white);
  --mantine-primary-color-contrast: var(--mantine-color-white);
  --mantine-color-error: var(--mantine-color-red-6);
  --mantine-color-placeholder: var(--mantine-color-gray-5);
  --mantine-color-anchor: var(--mantine-primary-color-filled);
  --mantine-color-default: var(--mantine-color-white);
  --mantine-color-default-hover: var(--mantine-color-gray-0);
  --mantine-color-default-color: var(--mantine-color-gray-9);
  --mantine-color-default-border: var(--mantine-color-gray-4);
  --mantine-color-dimmed: var(--mantine-color-gray-6);
  --mantine-color-dark-text: var(--mantine-color-dark-filled);
  --mantine-color-dark-filled: var(--mantine-color-dark-6);
  --mantine-color-dark-filled-hover: var(--mantine-color-dark-7);
  --mantine-color-dark-light: rgba(56, 56, 56, 0.1);
  --mantine-color-dark-light-hover: rgba(56, 56, 56, 0.12);
  --mantine-color-dark-light-color: var(--mantine-color-dark-6);
  --mantine-color-dark-outline: var(--mantine-color-dark-6);
  --mantine-color-dark-outline-hover: rgba(56, 56, 56, 0.05);
  --mantine-color-gray-text: var(--mantine-color-gray-filled);
  --mantine-color-gray-filled: var(--mantine-color-gray-6);
  --mantine-color-gray-filled-hover: var(--mantine-color-gray-7);
  --mantine-color-gray-light: rgba(134, 142, 150, 0.1);
  --mantine-color-gray-light-hover: rgba(134, 142, 150, 0.12);
  --mantine-color-gray-light-color: var(--mantine-color-gray-6);
  --mantine-color-gray-outline: var(--mantine-color-gray-6);
  --mantine-color-gray-outline-hover: rgba(134, 142, 150, 0.05);
  --mantine-color-red-text: var(--mantine-color-red-filled);
  --mantine-color-red-filled: var(--mantine-color-red-6);
  --mantine-color-red-filled-hover: var(--mantine-color-red-7);
  --mantine-color-red-light: rgba(250, 82, 82, 0.1);
  --mantine-color-red-light-hover: rgba(250, 82, 82, 0.12);
  --mantine-color-red-light-color: var(--mantine-color-red-6);
  --mantine-color-red-outline: var(--mantine-color-red-6);
  --mantine-color-red-outline-hover: rgba(250, 82, 82, 0.05);
  --mantine-color-pink-text: var(--mantine-color-pink-filled);
  --mantine-color-pink-filled: var(--mantine-color-pink-6);
  --mantine-color-pink-filled-hover: var(--mantine-color-pink-7);
  --mantine-color-pink-light: rgba(230, 73, 128, 0.1);
  --mantine-color-pink-light-hover: rgba(230, 73, 128, 0.12);
  --mantine-color-pink-light-color: var(--mantine-color-pink-6);
  --mantine-color-pink-outline: var(--mantine-color-pink-6);
  --mantine-color-pink-outline-hover: rgba(230, 73, 128, 0.05);
  --mantine-color-grape-text: var(--mantine-color-grape-filled);
  --mantine-color-grape-filled: var(--mantine-color-grape-6);
  --mantine-color-grape-filled-hover: var(--mantine-color-grape-7);
  --mantine-color-grape-light: rgba(190, 75, 219, 0.1);
  --mantine-color-grape-light-hover: rgba(190, 75, 219, 0.12);
  --mantine-color-grape-light-color: var(--mantine-color-grape-6);
  --mantine-color-grape-outline: var(--mantine-color-grape-6);
  --mantine-color-grape-outline-hover: rgba(190, 75, 219, 0.05);
  --mantine-color-violet-text: var(--mantine-color-violet-filled);
  --mantine-color-violet-filled: var(--mantine-color-violet-6);
  --mantine-color-violet-filled-hover: var(--mantine-color-violet-7);
  --mantine-color-violet-light: rgba(121, 80, 242, 0.1);
  --mantine-color-violet-light-hover: rgba(121, 80, 242, 0.12);
  --mantine-color-violet-light-color: var(--mantine-color-violet-6);
  --mantine-color-violet-outline: var(--mantine-color-violet-6);
  --mantine-color-violet-outline-hover: rgba(121, 80, 242, 0.05);
  --mantine-color-indigo-text: var(--mantine-color-indigo-filled);
  --mantine-color-indigo-filled: var(--mantine-color-indigo-6);
  --mantine-color-indigo-filled-hover: var(--mantine-color-indigo-7);
  --mantine-color-indigo-light: rgba(76, 110, 245, 0.1);
  --mantine-color-indigo-light-hover: rgba(76, 110, 245, 0.12);
  --mantine-color-indigo-light-color: var(--mantine-color-indigo-6);
  --mantine-color-indigo-outline: var(--mantine-color-indigo-6);
  --mantine-color-indigo-outline-hover: rgba(76, 110, 245, 0.05);
  --mantine-color-blue-text: var(--mantine-color-blue-filled);
  --mantine-color-blue-filled: var(--mantine-color-blue-6);
  --mantine-color-blue-filled-hover: var(--mantine-color-blue-7);
  --mantine-color-blue-light: rgba(34, 139, 230, 0.1);
  --mantine-color-blue-light-hover: rgba(34, 139, 230, 0.12);
  --mantine-color-blue-light-color: var(--mantine-color-blue-6);
  --mantine-color-blue-outline: var(--mantine-color-blue-6);
  --mantine-color-blue-outline-hover: rgba(34, 139, 230, 0.05);
  --mantine-color-cyan-text: var(--mantine-color-cyan-filled);
  --mantine-color-cyan-filled: var(--mantine-color-cyan-6);
  --mantine-color-cyan-filled-hover: var(--mantine-color-cyan-7);
  --mantine-color-cyan-light: rgba(21, 170, 191, 0.1);
  --mantine-color-cyan-light-hover: rgba(21, 170, 191, 0.12);
  --mantine-color-cyan-light-color: var(--mantine-color-cyan-6);
  --mantine-color-cyan-outline: var(--mantine-color-cyan-6);
  --mantine-color-cyan-outline-hover: rgba(21, 170, 191, 0.05);
  --mantine-color-teal-text: var(--mantine-color-teal-filled);
  --mantine-color-teal-filled: var(--mantine-color-teal-6);
  --mantine-color-teal-filled-hover: var(--mantine-color-teal-7);
  --mantine-color-teal-light: rgba(18, 184, 134, 0.1);
  --mantine-color-teal-light-hover: rgba(18, 184, 134, 0.12);
  --mantine-color-teal-light-color: var(--mantine-color-teal-6);
  --mantine-color-teal-outline: var(--mantine-color-teal-6);
  --mantine-color-teal-outline-hover: rgba(18, 184, 134, 0.05);
  --mantine-color-green-text: var(--mantine-color-green-filled);
  --mantine-color-green-filled: var(--mantine-color-green-6);
  --mantine-color-green-filled-hover: var(--mantine-color-green-7);
  --mantine-color-green-light: rgba(64, 192, 87, 0.1);
  --mantine-color-green-light-hover: rgba(64, 192, 87, 0.12);
  --mantine-color-green-light-color: var(--mantine-color-green-6);
  --mantine-color-green-outline: var(--mantine-color-green-6);
  --mantine-color-green-outline-hover: rgba(64, 192, 87, 0.05);
  --mantine-color-lime-text: var(--mantine-color-lime-filled);
  --mantine-color-lime-filled: var(--mantine-color-lime-6);
  --mantine-color-lime-filled-hover: var(--mantine-color-lime-7);
  --mantine-color-lime-light: rgba(130, 201, 30, 0.1);
  --mantine-color-lime-light-hover: rgba(130, 201, 30, 0.12);
  --mantine-color-lime-light-color: var(--mantine-color-lime-6);
  --mantine-color-lime-outline: var(--mantine-color-lime-6);
  --mantine-color-lime-outline-hover: rgba(130, 201, 30, 0.05);
  --mantine-color-yellow-text: var(--mantine-color-yellow-filled);
  --mantine-color-yellow-filled: var(--mantine-color-yellow-6);
  --mantine-color-yellow-filled-hover: var(--mantine-color-yellow-7);
  --mantine-color-yellow-light: rgba(250, 176, 5, 0.1);
  --mantine-color-yellow-light-hover: rgba(250, 176, 5, 0.12);
  --mantine-color-yellow-light-color: var(--mantine-color-yellow-6);
  --mantine-color-yellow-outline: var(--mantine-color-yellow-6);
  --mantine-color-yellow-outline-hover: rgba(250, 176, 5, 0.05);
  --mantine-color-orange-text: var(--mantine-color-orange-filled);
  --mantine-color-orange-filled: var(--mantine-color-orange-6);
  --mantine-color-orange-filled-hover: var(--mantine-color-orange-7);
  --mantine-color-orange-light: rgba(253, 126, 20, 0.1);
  --mantine-color-orange-light-hover: rgba(253, 126, 20, 0.12);
  --mantine-color-orange-light-color: var(--mantine-color-orange-6);
  --mantine-color-orange-outline: var(--mantine-color-orange-6);
  --mantine-color-orange-outline-hover: rgba(253, 126, 20, 0.05);
}

@font-face {
font-family: '__inter_650d79';
src: url(/_next/static/media/055fe2efe01b0ce2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__inter_650d79';
src: url(/_next/static/media/161ec9b856c3b8c6-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__inter_650d79';
src: url(/_next/static/media/816386e8342179fd-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__inter_650d79';
src: url(/_next/static/media/dbab90fb9d6f23d1-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__inter_Fallback_650d79';src: local("Arial");ascent-override: 98.56%;descent-override: 24.54%;line-gap-override: 0.00%;size-adjust: 98.29%
}.__className_650d79 {font-family: '__inter_650d79', '__inter_Fallback_650d79'
}

.m_87cf2631 {
  background-color: transparent;
  cursor: pointer;
  border: 0;
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: var(--mantine-font-size-md);
  text-align: left;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

  :where([dir="rtl"]) .m_87cf2631 {
    text-align: right;
}

.m_77c9d27d {
  --button-height-xs: 30px;
  --button-height-sm: 36px;
  --button-height-md: 42px;
  --button-height-lg: 50px;
  --button-height-xl: 60px;

  --button-height-compact-xs: 22px;
  --button-height-compact-sm: 26px;
  --button-height-compact-md: 30px;
  --button-height-compact-lg: 34px;
  --button-height-compact-xl: 40px;

  --button-padding-x-xs: 14px;
  --button-padding-x-sm: 18px;
  --button-padding-x-md: 22px;
  --button-padding-x-lg: 26px;
  --button-padding-x-xl: 32px;

  --button-padding-x-compact-xs: 7px;
  --button-padding-x-compact-sm: 8px;
  --button-padding-x-compact-md: 10px;
  --button-padding-x-compact-lg: 12px;
  --button-padding-x-compact-xl: 14px;

  --button-height: var(--button-height-sm);
  --button-padding-x: var(--button-padding-x-sm);
  --button-color: var(--mantine-color-white);

  -webkit-user-select: none;

     -moz-user-select: none;

          user-select: none;
  font-weight: 600;
  position: relative;
  line-height: 1;
  text-align: center;
  overflow: hidden;

  width: auto;
  cursor: pointer;
  display: inline-block;
  border-radius: var(--button-radius, var(--mantine-radius-default));
  font-size: var(--button-fz, var(--mantine-font-size-sm));
  background: var(--button-bg, var(--mantine-primary-color-filled));
  border: var(--button-bd, calc(0.0625rem * var(--mantine-scale)) solid transparent);
  color: var(--button-color, var(--mantine-color-white));
  height: 36px;
  height: var(--button-height, var(--button-height-sm));
  padding-left: 18px;
  padding-right: 18px;
  padding-left: var(--button-padding-x, var(--button-padding-x-sm));
  padding-right: var(--button-padding-x, var(--button-padding-x-sm));
  vertical-align: middle;
}

  .m_77c9d27d:where([data-block]) {
    display: block;
    width: 100%;
  }

  .m_77c9d27d:where([data-with-left-section]) {
    padding-left: calc(var(--button-padding-x) / 1.5);
  }

  .m_77c9d27d:where([data-with-right-section]) {
    padding-right: calc(var(--button-padding-x) / 1.5);
  }

  .m_77c9d27d:where(:disabled:not([data-loading]), [data-disabled]:not([data-loading])) {
    cursor: not-allowed;
    border: 1px solid transparent;
    transform: none;
  }

  :where([data-mantine-color-scheme='light']) .m_77c9d27d:where(:disabled:not([data-loading]), [data-disabled]:not([data-loading])) {
      color: var(--mantine-color-gray-5);
      background: var(--mantine-color-gray-1);
}

  :where([data-mantine-color-scheme='dark']) .m_77c9d27d:where(:disabled:not([data-loading]), [data-disabled]:not([data-loading])) {
      color: var(--mantine-color-dark-3);
      background: var(--mantine-color-dark-6);
}

  .m_77c9d27d::before {
    content: '';
    pointer-events: none;
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-radius: var(--button-radius, var(--mantine-radius-default));
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(12px);
    transition:
      transform 150ms ease, opacity 100ms ease;
  }

  :where([data-mantine-color-scheme='light']) .m_77c9d27d::before {
      background-color: rgba(255, 255, 255, 0.15);
}

  :where([data-mantine-color-scheme='dark']) .m_77c9d27d::before {
      background-color: rgba(0, 0, 0, 0.15);
}

  .m_77c9d27d:where([data-loading]) {
    cursor: not-allowed;
    transform: none;
  }

  .m_77c9d27d:where([data-loading])::before {
      transform: translateY(0);
      opacity: 1;
    }

  .m_77c9d27d:where([data-loading]) .m_80f1301b {
      opacity: 0;
      transform: translateY(100%);
    }

  @media (hover: hover) {
    .m_77c9d27d:hover:where(:not([data-loading]):not(:disabled):not([data-disabled])) {
      background-color: var(--button-hover, var(--mantine-primary-color-filled-hover));
      color: var(--button-hover-color, var(--button-color));
    }
}

  @media (hover: none) {
    .m_77c9d27d:active:where(:not([data-loading]):not(:disabled):not([data-disabled])) {
      background-color: var(--button-hover, var(--mantine-primary-color-filled-hover));
      color: var(--button-hover-color, var(--button-color));
    }
}

.m_80f1301b {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: var(--button-justify, center);
  height: 100%;
  overflow: visible;
  transition:
    transform 150ms ease, opacity 100ms ease;
}

.m_811560b9 {
  white-space: nowrap;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  opacity: 1;
}

.m_811560b9:where([data-loading]) {
    opacity: 0.2;
  }

.m_a74036a {
  display: flex;
  align-items: center;
}

.m_a74036a:where([data-position='left']) {
    margin-right: var(--mantine-spacing-xs);
  }

.m_a74036a:where([data-position='right']) {
    margin-left: var(--mantine-spacing-xs);
  }

.m_a25b86ee {
  position: absolute;
  left: 50%;
  top: 50%;
}

.m_80d6d844 {
  --button-border-width: 1px;
  display: flex;
}

.m_80d6d844 :where(.m_77c9d27d):focus {
      position: relative;
      z-index: 1;
    }

.m_80d6d844[data-orientation='horizontal'] {
    flex-direction: row;
  }

.m_80d6d844[data-orientation='horizontal'] .m_77c9d27d:not(:only-child):first-child, .m_80d6d844[data-orientation='horizontal'] .m_70be2a01:not(:only-child):first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right-width: calc(var(--button-border-width) / 2);
      }

.m_80d6d844[data-orientation='horizontal'] .m_77c9d27d:not(:only-child):last-child, .m_80d6d844[data-orientation='horizontal'] .m_70be2a01:not(:only-child):last-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left-width: calc(var(--button-border-width) / 2);
      }

.m_80d6d844[data-orientation='horizontal'] .m_77c9d27d:not(:only-child):not(:first-child):not(:last-child), .m_80d6d844[data-orientation='horizontal'] .m_70be2a01:not(:only-child):not(:first-child):not(:last-child) {
        border-radius: 0;
        border-left-width: calc(var(--button-border-width) / 2);
        border-right-width: calc(var(--button-border-width) / 2);
      }

.m_80d6d844[data-orientation='vertical'] {
    flex-direction: column;
  }

.m_80d6d844[data-orientation='vertical'] .m_77c9d27d:not(:only-child):first-child, .m_80d6d844[data-orientation='vertical'] .m_70be2a01:not(:only-child):first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-width: calc(var(--button-border-width) / 2);
      }

.m_80d6d844[data-orientation='vertical'] .m_77c9d27d:not(:only-child):last-child, .m_80d6d844[data-orientation='vertical'] .m_70be2a01:not(:only-child):last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top-width: calc(var(--button-border-width) / 2);
      }

.m_80d6d844[data-orientation='vertical'] .m_77c9d27d:not(:only-child):not(:first-child):not(:last-child), .m_80d6d844[data-orientation='vertical'] .m_70be2a01:not(:only-child):not(:first-child):not(:last-child) {
        border-radius: 0;
        border-bottom-width: calc(var(--button-border-width) / 2);
        border-top-width: calc(var(--button-border-width) / 2);
      }

.m_70be2a01 {
  --section-height-xs: 30px;
  --section-height-sm: 36px;
  --section-height-md: 42px;
  --section-height-lg: 50px;
  --section-height-xl: 60px;

  --section-height-compact-xs: 22px;
  --section-height-compact-sm: 26px;
  --section-height-compact-md: 30px;
  --section-height-compact-lg: 34px;
  --section-height-compact-xl: 40px;

  --section-padding-x-xs: 14px;
  --section-padding-x-sm: 18px;
  --section-padding-x-md: 22px;
  --section-padding-x-lg: 26px;
  --section-padding-x-xl: 32px;

  --section-padding-x-compact-xs: 7px;
  --section-padding-x-compact-sm: 8px;
  --section-padding-x-compact-md: 10px;
  --section-padding-x-compact-lg: 12px;
  --section-padding-x-compact-xl: 14px;

  --section-height: var(--section-height-sm);
  --section-padding-x: var(--section-padding-x-sm);
  --section-color: var(--mantine-color-white);

  font-weight: 600;
  width: auto;
  border-radius: var(--section-radius, var(--mantine-radius-default));
  font-size: var(--section-fz, var(--mantine-font-size-sm));
  background: var(--section-bg, var(--mantine-primary-color-filled));
  border: var(--section-bd, calc(0.0625rem * var(--mantine-scale)) solid transparent);
  color: var(--section-color, var(--mantine-color-white));
  height: 36px;
  height: var(--section-height, var(--section-height-sm));
  padding-left: 18px;
  padding-right: 18px;
  padding-left: var(--section-padding-x, var(--section-padding-x-sm));
  padding-right: var(--section-padding-x, var(--section-padding-x-sm));
  vertical-align: middle;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.m_9bdbb667 {
  --accordion-radius: var(--mantine-radius-default);
}

.m_df78851f {
  word-break: break-word;
}

.m_4ba554d4 {
  padding: var(--mantine-spacing-md);
  padding-top: calc(var(--mantine-spacing-xs) / 2);
}

.m_8fa820a0 {
  margin: 0;
  padding: 0;
}

.m_4ba585b8 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  opacity: 1;
  cursor: pointer;
  background-color: transparent;
}

.m_4ba585b8:where([data-chevron-position='left']) {
    flex-direction: row;
    padding-left: 0;
  }

:where([data-mantine-color-scheme='light']) .m_4ba585b8 {
    color: var(--mantine-color-black);
}

:where([data-mantine-color-scheme='dark']) .m_4ba585b8 {
    color: var(--mantine-color-dark-0);
}

.m_4ba585b8:where(:disabled, [data-disabled]) {
    opacity: 0.4;
    cursor: not-allowed;
  }

@media (hover: hover) {
      :where([data-mantine-color-scheme='light']) .m_6939a5e9:where(:not(:disabled):not([data-disabled])):hover, :where([data-mantine-color-scheme='light']) .m_4271d21b:where(:not(:disabled):not([data-disabled])):hover {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_6939a5e9:where(:not(:disabled):not([data-disabled])):hover, :where([data-mantine-color-scheme='dark']) .m_4271d21b:where(:not(:disabled):not([data-disabled])):hover {
        background-color: var(--mantine-color-dark-6);
  }
}

@media (hover: none) {
      :where([data-mantine-color-scheme='light']) .m_6939a5e9:where(:not(:disabled):not([data-disabled])):active, :where([data-mantine-color-scheme='light']) .m_4271d21b:where(:not(:disabled):not([data-disabled])):active {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_6939a5e9:where(:not(:disabled):not([data-disabled])):active, :where([data-mantine-color-scheme='dark']) .m_4271d21b:where(:not(:disabled):not([data-disabled])):active {
        background-color: var(--mantine-color-dark-6);
  }
}

.m_df3ffa0f {
  color: inherit;
  font-weight: 400;
  flex: 1 1;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: var(--mantine-spacing-sm);
  padding-bottom: var(--mantine-spacing-sm);
}

.m_3f35ae96 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: transform 200ms ease;
  transition: transform var(--accordion-transition-duration, 200ms) ease;
  width: var(--accordion-chevron-size, calc(0.9375rem * var(--mantine-scale)));
  min-width: var(--accordion-chevron-size, calc(0.9375rem * var(--mantine-scale)));
  transform: rotate(0deg);
}

.m_3f35ae96:where([data-rotate]) {
    transform: rotate(180deg);
  }

.m_3f35ae96:where([data-position='left']) {
    margin-right: var(--mantine-spacing-md);
    margin-left: var(--mantine-spacing-md);
  }

.m_9bd771fe {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--mantine-spacing-sm);
}

.m_9bd771fe:where([data-chevron-position='left']) {
    margin-right: 0;
    margin-left: var(--mantine-spacing-lg);
  }

:where([data-mantine-color-scheme='light']) .m_9bd7b098 {
    --item-border-color: var(--mantine-color-gray-3);
    --item-filled-color: var(--mantine-color-gray-0);
}

:where([data-mantine-color-scheme='dark']) .m_9bd7b098 {
    --item-border-color: var(--mantine-color-dark-4);
    --item-filled-color: var(--mantine-color-dark-6);
}

.m_fe19b709 {
  border-bottom: 1px solid var(--item-border-color);
}

.m_1f921b3b {
  border: 1px solid var(--item-border-color);
  transition: background-color 150ms ease;
}

.m_1f921b3b:where([data-active]) {
    background-color: var(--item-filled-color);
  }

.m_1f921b3b:first-of-type {
    border-top-left-radius: var(--accordion-radius);
    border-top-right-radius: var(--accordion-radius);
  }

.m_1f921b3b:first-of-type > [data-accordion-control] {
      border-top-left-radius: var(--accordion-radius);
      border-top-right-radius: var(--accordion-radius);
    }

.m_1f921b3b:last-of-type {
    border-bottom-left-radius: var(--accordion-radius);
    border-bottom-right-radius: var(--accordion-radius);
  }

.m_1f921b3b:last-of-type > [data-accordion-control] {
      border-bottom-left-radius: var(--accordion-radius);
      border-bottom-right-radius: var(--accordion-radius);
    }

.m_1f921b3b + .m_1f921b3b {
    border-top: 0;
  }

.m_2cdf939a {
  border-radius: var(--accordion-radius);
}

.m_2cdf939a:where([data-active]) {
    background-color: var(--item-filled-color);
  }

.m_9f59b069 {
  background-color: var(--item-filled-color);
  border-radius: var(--accordion-radius);
  border: 1px solid transparent;
  transition: background-color 150ms ease;
}

.m_9f59b069[data-active] {
    border-color: var(--item-border-color);
  }

:where([data-mantine-color-scheme='light']) .m_9f59b069[data-active] {
      background-color: var(--mantine-color-white);
}

:where([data-mantine-color-scheme='dark']) .m_9f59b069[data-active] {
      background-color: var(--mantine-color-dark-7);
}

.m_9f59b069 + .m_9f59b069 {
    margin-top: var(--mantine-spacing-md);
  }

.m_8d3f4000 {
  --ai-size-xs: 18px;
  --ai-size-sm: 22px;
  --ai-size-md: 28px;
  --ai-size-lg: 34px;
  --ai-size-xl: 44px;

  --ai-size-input-xs: 30px;
  --ai-size-input-sm: 36px;
  --ai-size-input-md: 42px;
  --ai-size-input-lg: 50px;
  --ai-size-input-xl: 60px;

  --ai-size: var(--ai-size-md);
  --ai-color: var(--mantine-color-white);

  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  overflow: hidden;

  width: 28px;

  width: var(--ai-size);
  height: 28px;
  height: var(--ai-size);
  min-width: 28px;
  min-width: var(--ai-size);
  min-height: 28px;
  min-height: var(--ai-size);
  border-radius: var(--ai-radius, var(--mantine-radius-default));
  background: var(--ai-bg, var(--mantine-primary-color-filled));
  color: var(--ai-color, var(--mantine-color-white));
  border: var(--ai-bd, calc(0.0625rem * var(--mantine-scale)) solid transparent);
  cursor: pointer;
}

  @media (hover: hover) {
    .m_8d3f4000:hover:where(:not([data-loading]):not(:disabled):not([data-disabled])) {
      background-color: var(--ai-hover, var(--mantine-primary-color-filled-hover));
      color: var(--ai-hover-color, var(--ai-color));
    }
}

  @media (hover: none) {
    .m_8d3f4000:active:where(:not([data-loading]):not(:disabled):not([data-disabled])) {
      background-color: var(--ai-hover, var(--mantine-primary-color-filled-hover));
      color: var(--ai-hover-color, var(--ai-color));
    }
}

  .m_8d3f4000[data-loading] {
    cursor: not-allowed;
  }

  .m_8d3f4000[data-loading] .m_8d3afb97 {
      opacity: 0;
      transform: translateY(100%);
    }

  .m_8d3f4000:where(:disabled:not([data-loading]), [data-disabled]:not([data-loading])) {
    cursor: not-allowed;
    border: 1px solid transparent;
  }

  :where([data-mantine-color-scheme='light']) .m_8d3f4000:where(:disabled:not([data-loading]), [data-disabled]:not([data-loading])) {
      background-color: var(--mantine-color-gray-1);
      color: var(--mantine-color-gray-5);
}

  :where([data-mantine-color-scheme='dark']) .m_8d3f4000:where(:disabled:not([data-loading]), [data-disabled]:not([data-loading])) {
      background-color: var(--mantine-color-dark-6);
      color: var(--mantine-color-dark-3);
}

  .m_8d3f4000:where(:disabled:not([data-loading]), [data-disabled]:not([data-loading])):active {
      transform: none;
    }

.m_302b9fb1 {
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  position: absolute;
  border-radius: var(--ai-radius, var(--mantine-radius-default));
  display: flex;
  align-items: center;
  justify-content: center;
}

:where([data-mantine-color-scheme='light']) .m_302b9fb1 {
    background-color: rgba(255, 255, 255, 0.15);
}

:where([data-mantine-color-scheme='dark']) .m_302b9fb1 {
    background-color: rgba(0, 0, 0, 0.15);
}

.m_1a0f1b21 {
  --ai-border-width: 1px;
  display: flex;
}

.m_1a0f1b21 :where(*):focus {
      position: relative;
      z-index: 1;
    }

.m_1a0f1b21[data-orientation='horizontal'] {
    flex-direction: row;
  }

.m_1a0f1b21[data-orientation='horizontal'] .m_8d3f4000:not(:only-child):first-child, .m_1a0f1b21[data-orientation='horizontal'] .m_437b6484:not(:only-child):first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right-width: calc(var(--ai-border-width) / 2);
      }

.m_1a0f1b21[data-orientation='horizontal'] .m_8d3f4000:not(:only-child):last-child, .m_1a0f1b21[data-orientation='horizontal'] .m_437b6484:not(:only-child):last-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left-width: calc(var(--ai-border-width) / 2);
      }

.m_1a0f1b21[data-orientation='horizontal'] .m_8d3f4000:not(:only-child):not(:first-child):not(:last-child), .m_1a0f1b21[data-orientation='horizontal'] .m_437b6484:not(:only-child):not(:first-child):not(:last-child) {
        border-radius: 0;
        border-left-width: calc(var(--ai-border-width) / 2);
        border-right-width: calc(var(--ai-border-width) / 2);
      }

.m_1a0f1b21[data-orientation='vertical'] {
    flex-direction: column;
  }

.m_1a0f1b21[data-orientation='vertical'] .m_8d3f4000:not(:only-child):first-child, .m_1a0f1b21[data-orientation='vertical'] .m_437b6484:not(:only-child):first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-width: calc(var(--ai-border-width) / 2);
      }

.m_1a0f1b21[data-orientation='vertical'] .m_8d3f4000:not(:only-child):last-child, .m_1a0f1b21[data-orientation='vertical'] .m_437b6484:not(:only-child):last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top-width: calc(var(--ai-border-width) / 2);
      }

.m_1a0f1b21[data-orientation='vertical'] .m_8d3f4000:not(:only-child):not(:first-child):not(:last-child), .m_1a0f1b21[data-orientation='vertical'] .m_437b6484:not(:only-child):not(:first-child):not(:last-child) {
        border-radius: 0;
        border-bottom-width: calc(var(--ai-border-width) / 2);
        border-top-width: calc(var(--ai-border-width) / 2);
      }

.m_8d3afb97 {
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    transform 150ms ease, opacity 100ms ease;
  width: 100%;
  height: 100%;
}

.m_437b6484 {
  --section-height-xs: 18px;
  --section-height-sm: 22px;
  --section-height-md: 28px;
  --section-height-lg: 34px;
  --section-height-xl: 44px;

  --section-height-input-xs: 30px;
  --section-height-input-sm: 36px;
  --section-height-input-md: 42px;
  --section-height-input-lg: 50px;
  --section-height-input-xl: 60px;

  --section-padding-x-xs: 6px;
  --section-padding-x-sm: 8px;
  --section-padding-x-md: 10px;
  --section-padding-x-lg: 12px;
  --section-padding-x-xl: 16px;

  --section-height: var(--section-height-sm);
  --section-padding-x: var(--section-padding-x-sm);
  --section-color: var(--mantine-color-white);

  font-weight: 600;
  width: auto;
  border-radius: var(--section-radius, var(--mantine-radius-default));
  font-size: var(--section-fz, var(--mantine-font-size-sm));
  background: var(--section-bg, var(--mantine-primary-color-filled));
  border: var(--section-bd, calc(0.0625rem * var(--mantine-scale)) solid transparent);
  color: var(--section-color, var(--mantine-color-white));
  height: 22px;
  height: var(--section-height, var(--section-height-sm));
  padding-left: 8px;
  padding-right: 8px;
  padding-left: var(--section-padding-x, var(--section-padding-x-sm));
  padding-right: var(--section-padding-x, var(--section-padding-x-sm));
  vertical-align: middle;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.m_849cf0da {
  color: var(--mantine-color-anchor);
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  display: inline;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
}

  @media (hover: hover) {

  .m_849cf0da:where([data-underline='hover']):hover {
      -webkit-text-decoration: underline;
      text-decoration: underline;
  }
}

  @media (hover: none) {

  .m_849cf0da:where([data-underline='hover']):active {
      -webkit-text-decoration: underline;
      text-decoration: underline;
  }
}

  .m_849cf0da:where([data-underline='always']) {
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }

  .m_849cf0da:where([data-variant='gradient']),
    .m_849cf0da:where([data-variant='gradient']):hover {
      -webkit-text-decoration: none;
      text-decoration: none;
    }

  .m_849cf0da:where([data-line-clamp]) {
    display: -webkit-box;
  }

.m_71ac47fc {
  --ar-ratio: 1;
  max-width: 100%;
}

  .m_71ac47fc > :where(*:not(style)) {
    aspect-ratio: var(--ar-ratio);
    width: 100%;
  }

  .m_71ac47fc > :where(img, video) {
    -o-object-fit: cover;
       object-fit: cover;
  }

.m_347db0ec {
  --badge-height-xs: 16px;
  --badge-height-sm: 18px;
  --badge-height-md: 20px;
  --badge-height-lg: 26px;
  --badge-height-xl: 32px;

  --badge-fz-xs: 9px;
  --badge-fz-sm: 10px;
  --badge-fz-md: 11px;
  --badge-fz-lg: 13px;
  --badge-fz-xl: 16px;

  --badge-padding-x-xs: 6px;
  --badge-padding-x-sm: 8px;
  --badge-padding-x-md: 10px;
  --badge-padding-x-lg: 12px;
  --badge-padding-x-xl: 16px;

  --badge-height: var(--badge-height-md);
  --badge-fz: var(--badge-fz-md);
  --badge-padding-x: var(--badge-padding-x-md);
  --badge-radius: 1000px;
  --badge-lh: calc(var(--badge-height) - calc(0.125rem * var(--mantine-scale)));
  --badge-color: var(--mantine-color-white);
  --badge-bg: var(--mantine-primary-color-filled);
  --badge-border-width: 1px;
  --badge-bd: var(--badge-border-width) solid transparent;

  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  font-size: var(--badge-fz);
  border-radius: 1000px;
  border-radius: var(--badge-radius);
  height: 20px;
  height: var(--badge-height);
  line-height: var(--badge-lh);
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 0 10px;
  padding: 0 var(--badge-padding-x);
  display: inline-grid;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.25px;
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--badge-color);
  background: var(--badge-bg);
  border: 1px solid transparent;
  border: var(--badge-bd);
}

  .m_347db0ec:where([data-with-left-section], [data-variant='dot']) {
    grid-template-columns: auto 1fr;
  }

  .m_347db0ec:where([data-with-right-section]) {
    grid-template-columns: 1fr auto;
  }

  .m_347db0ec:where(
      [data-with-left-section][data-with-right-section],
      [data-variant='dot'][data-with-right-section]
    ) {
    grid-template-columns: auto 1fr auto;
  }

  .m_347db0ec:where([data-block]) {
    display: flex;
    width: 100%;
  }

  .m_347db0ec:where([data-circle]) {
    padding-left: 2px;
    padding-right: 2px;
    display: flex;
    width: var(--badge-height);
  }

.m_fbd81e3d {
  --badge-dot-size: calc(var(--badge-height) / 3.4);
}

:where([data-mantine-color-scheme='light']) .m_fbd81e3d {
    background-color: var(--mantine-color-white);
    border-color: var(--mantine-color-gray-4);
    color: var(--mantine-color-black);
}

:where([data-mantine-color-scheme='dark']) .m_fbd81e3d {
    background-color: var(--mantine-color-dark-5);
    border-color: var(--mantine-color-dark-5);
    color: var(--mantine-color-white);
}

.m_fbd81e3d::before {
    content: '';
    display: block;
    width: var(--badge-dot-size);
    height: var(--badge-dot-size);
    border-radius: var(--badge-dot-size);
    background-color: var(--badge-dot-color);
    margin-right: var(--badge-dot-size);
  }

.m_5add502a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  cursor: inherit;
}

.m_91fdda9b {
  --badge-section-margin: calc(var(--mantine-spacing-xs) / 2);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-height: calc(var(--badge-height) - var(--badge-border-width) * 2);
}

.m_91fdda9b:where([data-position='left']) {
    margin-right: var(--badge-section-margin);
  }

.m_91fdda9b:where([data-position='right']) {
    margin-left: var(--badge-section-margin);
  }

.m_fea6bf1a {
  --burger-size-xs: 12px;
  --burger-size-sm: 18px;
  --burger-size-md: 24px;
  --burger-size-lg: 34px;
  --burger-size-xl: 42px;

  --burger-size: var(--burger-size-md);
  --burger-line-size: calc(var(--burger-size) / 12);

  width: calc(24px + var(--mantine-spacing-xs));

  width: calc(var(--burger-size) + var(--mantine-spacing-xs));
  height: calc(24px + var(--mantine-spacing-xs));
  height: calc(var(--burger-size) + var(--mantine-spacing-xs));
  padding: calc(var(--mantine-spacing-xs) / 2);
  cursor: pointer;
}

  :where([data-mantine-color-scheme='light']) .m_fea6bf1a {
    --burger-color: var(--mantine-color-black);
}

  :where([data-mantine-color-scheme='dark']) .m_fea6bf1a {
    --burger-color: var(--mantine-color-white);
}

.m_d4fb9cad {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.m_d4fb9cad,
  .m_d4fb9cad::before,
  .m_d4fb9cad::after {
    display: block;
    width: var(--burger-size);
    height: var(--burger-line-size);
    background-color: var(--burger-color);
    outline: 1px solid transparent;
    transition-property: background-color, transform;
    transition-duration: 300ms;
    transition-duration: var(--burger-transition-duration, 300ms);
    transition-timing-function: ease;
    transition-timing-function: var(--burger-transition-timing-function, ease);
  }

.m_d4fb9cad::before,
  .m_d4fb9cad::after {
    position: absolute;
    content: '';
    left: 0;
  }

.m_d4fb9cad::before {
    top: calc(var(--burger-size) / -3);
  }

.m_d4fb9cad::after {
    top: calc(var(--burger-size) / 3);
  }

.m_d4fb9cad[data-opened] {
    background-color: transparent;
  }

.m_d4fb9cad[data-opened]::before {
      transform: translateY(calc(var(--burger-size) / 3)) rotate(45deg);
    }

.m_d4fb9cad[data-opened]::after {
      transform: translateY(calc(var(--burger-size) / -3)) rotate(-45deg);
    }

.m_e615b15f {
  --card-padding: var(--mantine-spacing-md);

  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  color: var(--mantine-color-text);
}

  :where([data-mantine-color-scheme='light']) .m_e615b15f {
    background-color: var(--mantine-color-white);
}

  :where([data-mantine-color-scheme='dark']) .m_e615b15f {
    background-color: var(--mantine-color-dark-6);
}

.m_599a2148 {
  display: block;
  margin-left: calc(var(--card-padding) * -1);
  margin-right: calc(var(--card-padding) * -1);
}

.m_599a2148:where(:first-child) {
    margin-top: calc(var(--card-padding) * -1);
    border-top: none !important;
  }

.m_599a2148:where(:last-child) {
    margin-bottom: calc(var(--card-padding) * -1);
    border-bottom: none !important;
  }

.m_599a2148:where([data-inherit-padding]) {
    padding-left: var(--card-padding);
    padding-right: var(--card-padding);
  }

.m_599a2148:where([data-with-border]) {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }

:where([data-mantine-color-scheme='light']) .m_599a2148 {
    border-color: var(--mantine-color-gray-3);
}

:where([data-mantine-color-scheme='dark']) .m_599a2148 {
    border-color: var(--mantine-color-dark-4);
}

.m_599a2148 + .m_599a2148 {
    border-top: none !important;
  }

.m_f59ffda3 {
  --chip-size-xs: 23px;
  --chip-size-sm: 28px;
  --chip-size-md: 32px;
  --chip-size-lg: 36px;
  --chip-size-xl: 40px;

  --chip-icon-size-xs: 10px;
  --chip-icon-size-sm: 12px;
  --chip-icon-size-md: 14px;
  --chip-icon-size-lg: 16px;
  --chip-icon-size-xl: 18px;

  --chip-padding-xs: 16px;
  --chip-padding-sm: 20px;
  --chip-padding-md: 24px;
  --chip-padding-lg: 28px;
  --chip-padding-xl: 32px;

  --chip-checked-padding-xs: 7.5px;
  --chip-checked-padding-sm: 10px;
  --chip-checked-padding-md: 11.7px;
  --chip-checked-padding-lg: 13.5px;
  --chip-checked-padding-xl: 15.7px;

  --chip-spacing-xs: 10px;
  --chip-spacing-sm: 12px;
  --chip-spacing-md: 16px;
  --chip-spacing-lg: 20px;
  --chip-spacing-xl: 22px;

  --chip-size: var(--chip-size-sm);
  --chip-icon-size: var(--chip-icon-size-sm);
  --chip-padding: var(--chip-padding-sm);
  --chip-spacing: var(--chip-spacing-sm);
  --chip-checked-padding: var(--chip-checked-padding-sm);
  --chip-bg: var(--mantine-primary-color-filled);
  --chip-hover: var(--mantine-primary-color-filled-hover);
  --chip-color: var(--mantine-color-white);
  --chip-bd: 1px solid transparent;
}

.m_be049a53 {
  display: inline-flex;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-radius: 1000rem;
  border-radius: var(--chip-radius, 1000rem);
  height: var(--chip-size);
  font-size: var(--chip-fz, var(--mantine-font-size-sm));
  line-height: calc(var(--chip-size) - (0.125rem * var(--mantine-scale)));
  line-height: calc(var(--chip-size) - calc(0.125rem * var(--mantine-scale)));
  padding-left: var(--chip-padding);
  padding-right: var(--chip-padding);
  cursor: pointer;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  border: 1px solid transparent;
  color: var(--mantine-color-text);
}

.m_be049a53:where([data-checked]) {
    padding: var(--chip-checked-padding);
  }

.m_be049a53:where([data-disabled]) {
    cursor: not-allowed;
  }

:where([data-mantine-color-scheme='light']) .m_be049a53:where([data-disabled]) {
      background-color: var(--mantine-color-gray-2);
      color: var(--mantine-color-gray-5);
}

:where([data-mantine-color-scheme='dark']) .m_be049a53:where([data-disabled]) {
      background-color: var(--mantine-color-dark-6);
      color: var(--mantine-color-dark-3);
}

:where([data-mantine-color-scheme='light']) .m_3904c1af:not([data-disabled]) {
    background-color: var(--mantine-color-white);
    border: 1px solid var(--mantine-color-gray-3);
}

:where([data-mantine-color-scheme='dark']) .m_3904c1af:not([data-disabled]) {
    background-color: var(--mantine-color-dark-6);
    border: 1px solid var(--mantine-color-dark-4);
}

@media (hover: hover) {
    :where([data-mantine-color-scheme='light']) .m_3904c1af:not([data-disabled]):hover {
      background-color: var(--mantine-color-gray-0);
  }

    :where([data-mantine-color-scheme='dark']) .m_3904c1af:not([data-disabled]):hover {
      background-color: var(--mantine-color-dark-5);
  }
}

@media (hover: none) {
    :where([data-mantine-color-scheme='light']) .m_3904c1af:not([data-disabled]):active {
      background-color: var(--mantine-color-gray-0);
  }

    :where([data-mantine-color-scheme='dark']) .m_3904c1af:not([data-disabled]):active {
      background-color: var(--mantine-color-dark-5);
  }
}

.m_3904c1af:not([data-disabled]):where([data-checked]) {
    --chip-icon-color: var(--chip-color);
    border: var(--chip-bd);
  }

@media (hover: hover) {

  .m_3904c1af:not([data-disabled]):where([data-checked]):hover {
      background-color: var(--chip-hover);
  }
}

@media (hover: none) {

  .m_3904c1af:not([data-disabled]):where([data-checked]):active {
      background-color: var(--chip-hover);
  }
}

.m_fa109255:not([data-disabled]),
.m_f7e165c3:not([data-disabled]) {
  border: 1px solid transparent;
  color: var(--mantine-color-text);
}

:where([data-mantine-color-scheme='light']) .m_fa109255:not([data-disabled]), :where([data-mantine-color-scheme='light']) .m_f7e165c3:not([data-disabled]) {
    background-color: var(--mantine-color-gray-1);
}

:where([data-mantine-color-scheme='dark']) .m_fa109255:not([data-disabled]), :where([data-mantine-color-scheme='dark']) .m_f7e165c3:not([data-disabled]) {
    background-color: var(--mantine-color-dark-5);
}

@media (hover: hover) {
    :where([data-mantine-color-scheme='light']) .m_fa109255:not([data-disabled]):hover, :where([data-mantine-color-scheme='light']) .m_f7e165c3:not([data-disabled]):hover {
      background-color: var(--mantine-color-gray-2);
  }

    :where([data-mantine-color-scheme='dark']) .m_fa109255:not([data-disabled]):hover, :where([data-mantine-color-scheme='dark']) .m_f7e165c3:not([data-disabled]):hover {
      background-color: var(--mantine-color-dark-4);
  }
}

@media (hover: none) {
    :where([data-mantine-color-scheme='light']) .m_fa109255:not([data-disabled]):active, :where([data-mantine-color-scheme='light']) .m_f7e165c3:not([data-disabled]):active {
      background-color: var(--mantine-color-gray-2);
  }

    :where([data-mantine-color-scheme='dark']) .m_fa109255:not([data-disabled]):active, :where([data-mantine-color-scheme='dark']) .m_f7e165c3:not([data-disabled]):active {
      background-color: var(--mantine-color-dark-4);
  }
}

.m_fa109255:not([data-disabled]):where([data-checked]), .m_f7e165c3:not([data-disabled]):where([data-checked]) {
    --chip-icon-color: var(--chip-color);
    color: var(--chip-color);
    background-color: var(--chip-bg);
  }

@media (hover: hover) {

  .m_fa109255:not([data-disabled]):where([data-checked]):hover, .m_f7e165c3:not([data-disabled]):where([data-checked]):hover {
      background-color: var(--chip-hover);
  }
}

@media (hover: none) {

  .m_fa109255:not([data-disabled]):where([data-checked]):active, .m_f7e165c3:not([data-disabled]):where([data-checked]):active {
      background-color: var(--chip-hover);
  }
}

.m_9ac86df9 {
  width: calc(var(--chip-icon-size) + (var(--chip-spacing) / 1.5));
  max-width: calc(var(--chip-icon-size) + (var(--chip-spacing) / 1.5));
  height: var(--chip-icon-size);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.m_d6d72580 {
  width: var(--chip-icon-size);
  height: var(--chip-icon-size);
  display: block;
  color: inherit;
  color: var(--chip-icon-color, inherit);
}

.m_bde07329 {
  width: 0;
  height: 0;
  padding: 0;
  opacity: 0;
  margin: 0;
}

.m_bde07329:focus-visible + .m_be049a53 {
    outline: 2px solid var(--mantine-primary-color-filled);
    outline-offset: 2px;
  }

.m_7485cace {
  --container-size-xs: 540px;
  --container-size-sm: 720px;
  --container-size-md: 960px;
  --container-size-lg: 1140px;
  --container-size-xl: 1320px;
  --container-size: var(--container-size-md);

  max-width: 960px;

  max-width: var(--container-size);
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  margin-left: auto;
  margin-right: auto;
}

  .m_7485cace:where([data-fluid]) {
    max-width: 100%;
  }

.m_8478a6da {
  container: mantine-grid / inline-size;
}

.m_410352e9 {
  --grid-overflow: visible;
  --grid-margin: calc(var(--grid-gutter) / -2);
  --grid-col-padding: calc(var(--grid-gutter) / 2);

  overflow: visible;

  overflow: var(--grid-overflow);
}

.m_dee7bd2f {
  width: calc(100% + var(--grid-gutter));
  display: flex;
  flex-wrap: wrap;
  justify-content: var(--grid-justify);
  align-items: var(--grid-align);
  margin: var(--grid-margin);
}

.m_96bdd299 {
  --col-flex-grow: 0;
  --col-offset: 0rem;

  flex-shrink: 0;
  order: var(--col-order);
  flex-basis: var(--col-flex-basis);
  width: var(--col-width);
  max-width: var(--col-max-width);
  flex-grow: 0;
  flex-grow: var(--col-flex-grow);
  margin-left: 0rem;
  margin-left: var(--col-offset);
  padding: var(--grid-col-padding);
}

.m_4081bf90 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-wrap: var(--group-wrap, wrap);
  justify-content: flex-start;
  justify-content: var(--group-justify, flex-start);
  align-items: center;
  align-items: var(--group-align, center);
  gap: var(--group-gap, var(--mantine-spacing-md));
}

  .m_4081bf90:where([data-grow]) > * {
      flex-grow: 1;
      max-width: var(--group-child-width);
    }

.m_6c018570 {
  position: relative;
  margin-top: 0rem;
  margin-top: var(--input-margin-top, 0rem);
  margin-bottom: 0rem;
  margin-bottom: var(--input-margin-bottom, 0rem);

  --input-height-xs: 30px;
  --input-height-sm: 36px;
  --input-height-md: 42px;
  --input-height-lg: 50px;
  --input-height-xl: 60px;

  --input-padding-y-xs: 5px;
  --input-padding-y-sm: 6px;
  --input-padding-y-md: 8px;
  --input-padding-y-lg: 10px;
  --input-padding-y-xl: 13px;

  --input-height: var(--input-height-sm);
  --input-radius: var(--mantine-radius-default);

  --input-cursor: text;
  --input-text-align: left;
  --input-line-height: calc(var(--input-height) - calc(0.125rem * var(--mantine-scale)));
  --input-padding: calc(var(--input-height) / 3);
  --input-padding-inline-start: var(--input-padding);
  --input-padding-inline-end: var(--input-padding);
  --input-placeholder-color: var(--mantine-color-placeholder);
  --input-color: var(--mantine-color-text);

  --input-left-section-size: var(--input-left-section-width, calc(var(--input-height) - calc(0.125rem * var(--mantine-scale))));

  --input-right-section-size: var(
    --input-right-section-width,
    calc(var(--input-height) - calc(0.125rem * var(--mantine-scale)))
  );

  --input-size: var(--input-height);

  --section-y: 1px;
  --left-section-start: 1px;
  --left-section-border-radius: var(--input-radius) 0 0 var(--input-radius);

  --right-section-end: 1px;
  --right-section-border-radius: 0 var(--input-radius) var(--input-radius) 0;
}

  .m_6c018570[data-variant='unstyled'] {
    --input-padding: 0;
    --input-padding-y: 0;
    --input-padding-inline-start: 0;
    --input-padding-inline-end: 0;
  }

  .m_6c018570[data-pointer] {
    --input-cursor: pointer;
  }

  .m_6c018570[data-multiline] {
    --input-padding-y-xs: 4.5px;
    --input-padding-y-sm: 5.5px;
    --input-padding-y-md: 7px;
    --input-padding-y-lg: 9.5px;
    --input-padding-y-xl: 13px;

    --input-size: auto;
    --input-line-height: var(--mantine-line-height);
    --input-padding-y: var(--input-padding-y-sm);
  }

  .m_6c018570[data-with-left-section] {
    --input-padding-inline-start: var(--input-left-section-size);
  }

  .m_6c018570[data-with-right-section] {
    --input-padding-inline-end: var(--input-right-section-size);
  }

  [data-mantine-color-scheme='light'] .m_6c018570 {
    --input-disabled-bg: var(--mantine-color-gray-1);
    --input-disabled-color: var(--mantine-color-gray-6);
}

  [data-mantine-color-scheme='light'] .m_6c018570[data-variant='default'] {
      --input-bd: var(--mantine-color-gray-4);
      --input-bg: var(--mantine-color-white);
      --input-bd-focus: var(--mantine-primary-color-filled);
    }

  [data-mantine-color-scheme='light'] .m_6c018570[data-variant='filled'] {
      --input-bd: transparent;
      --input-bg: var(--mantine-color-gray-1);
      --input-bd-focus: var(--mantine-primary-color-filled);
    }

  [data-mantine-color-scheme='light'] .m_6c018570[data-variant='unstyled'] {
      --input-bd: transparent;
      --input-bg: transparent;
      --input-bd-focus: transparent;
    }

  [data-mantine-color-scheme='dark'] .m_6c018570 {
    --input-disabled-bg: var(--mantine-color-dark-6);
    --input-disabled-color: var(--mantine-color-dark-2);
}

  [data-mantine-color-scheme='dark'] .m_6c018570[data-variant='default'] {
      --input-bd: var(--mantine-color-dark-4);
      --input-bg: var(--mantine-color-dark-6);
      --input-bd-focus: var(--mantine-primary-color-filled);
    }

  [data-mantine-color-scheme='dark'] .m_6c018570[data-variant='filled'] {
      --input-bd: transparent;
      --input-bg: var(--mantine-color-dark-5);
      --input-bd-focus: var(--mantine-primary-color-filled);
    }

  [data-mantine-color-scheme='dark'] .m_6c018570[data-variant='unstyled'] {
      --input-bd: transparent;
      --input-bg: transparent;
      --input-bd-focus: transparent;
    }

  [data-mantine-color-scheme] .m_6c018570[data-error]:not([data-variant='unstyled']) {
      --input-bd: var(--mantine-color-error);
    }

  [data-mantine-color-scheme] .m_6c018570[data-error] {

    --input-color: var(--mantine-color-error);
    --input-placeholder-color: var(--mantine-color-error);
    --input-section-color: var(--mantine-color-error);
}

  :where([dir="rtl"]) .m_6c018570 {
    --input-text-align: right;
    --left-section-border-radius: 0 var(--input-radius) var(--input-radius) 0;
    --right-section-border-radius: var(--input-radius) 0 0 var(--input-radius);
}

.m_8fb7ebe7 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  resize: none;
  resize: var(--input-resize, none);
  display: block;
  width: 100%;
  transition: border-color 100ms ease;

  text-align: var(--input-text-align);
  color: var(--input-color);
  border: calc(0.0625rem * var(--mantine-scale)) solid var(--input-bd);
  background-color: var(--input-bg);
  font-family: var(--input-font-family, var(--mantine-font-family));
  height: var(--input-size);
  min-height: var(--input-height);
  line-height: var(--input-line-height);
  font-size: var(--input-fz, var(--input-fz, var(--mantine-font-size-sm)));
  border-radius: var(--input-radius);
  padding-left: var(--input-padding-inline-start);
  padding-right: var(--input-padding-inline-end);
  padding-top: 0rem;
  padding-top: var(--input-padding-y, 0rem);
  padding-bottom: 0rem;
  padding-bottom: var(--input-padding-y, 0rem);
  cursor: var(--input-cursor);
  overflow: var(--input-overflow);
}

/* Used as data attribute in Textarea component, does not have associated prop on the Input component */

.m_8fb7ebe7[data-no-overflow] {
    --input-overflow: hidden;
  }

/* Used as data attribute in JsonInput component, does not have associated prop on the Input component */

.m_8fb7ebe7[data-monospace] {
    --input-font-family: var(--mantine-font-family-monospace);
    --input-fz: calc(var(--input-fz, var(--mantine-font-size-sm)) - calc(0.125rem * var(--mantine-scale)));
  }

.m_8fb7ebe7:focus,
  .m_8fb7ebe7:focus-within {
    outline: none;
    --input-bd: var(--input-bd-focus);
  }

[data-error] .m_8fb7ebe7:focus, [data-error] .m_8fb7ebe7:focus-within {
      --input-bd: var(--mantine-color-error);
    }

.m_8fb7ebe7::-moz-placeholder {
    color: var(--input-placeholder-color);
    opacity: 1;
  }

.m_8fb7ebe7::placeholder {
    color: var(--input-placeholder-color);
    opacity: 1;
  }

.m_8fb7ebe7::-webkit-inner-spin-button,
  .m_8fb7ebe7::-webkit-outer-spin-button,
  .m_8fb7ebe7::-webkit-search-decoration,
  .m_8fb7ebe7::-webkit-search-cancel-button,
  .m_8fb7ebe7::-webkit-search-results-button,
  .m_8fb7ebe7::-webkit-search-results-decoration {
    -webkit-appearance: none;
            appearance: none;
  }

.m_8fb7ebe7[type='number'] {
    -moz-appearance: textfield;
  }

.m_8fb7ebe7:disabled,
  .m_8fb7ebe7[data-disabled] {
    cursor: not-allowed;
    opacity: 0.6;
    background-color: var(--input-disabled-bg);
    color: var(--input-disabled-color);
  }

/* Required to be a separate selector to work in Firefox, can be merged with &:disabled once :has is supported */

.m_8fb7ebe7:has(input:disabled) {
    cursor: not-allowed;
    opacity: 0.6;
    background-color: var(--input-disabled-bg);
    color: var(--input-disabled-color);
  }

.m_82577fc2 {
  pointer-events: var(--section-pointer-events);
  position: absolute;
  z-index: 1;
  left: var(--section-start);
  right: var(--section-end);
  bottom: var(--section-y);
  top: var(--section-y);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--section-size);
  border-radius: var(--section-border-radius);
  color: var(--input-section-color, var(--mantine-color-dimmed));
}

.m_82577fc2[data-position='right'] {
    --section-pointer-events: var(--input-right-section-pointer-events);
    --section-end: var(--right-section-end);
    --section-size: var(--input-right-section-size);
    --section-border-radius: var(--right-section-border-radius);
  }

.m_82577fc2[data-position='left'] {
    --section-pointer-events: var(--input-left-section-pointer-events);
    --section-start: var(--left-section-start);
    --section-size: var(--input-left-section-size);
    --section-border-radius: var(--left-section-border-radius);
  }

/* ----- Input.Placeholder ----- */
.m_88bacfd0 {
  color: var(--input-placeholder-color, var(--mantine-color-placeholder));
}
[data-error] .m_88bacfd0 {
    --input-placeholder-color: var(--input-color, var(--mantine-color-placeholder));
  }

/* ----- Input.Wrapper ----- */
.m_46b77525 {
  line-height: var(--mantine-line-height);
}

.m_8fdc1311 {
  display: inline-block;
  font-weight: 500;
  word-break: break-word;
  cursor: default;
  -webkit-tap-highlight-color: transparent;
  font-size: var(--input-label-size, var(--mantine-font-size-sm));
}

.m_78a94662 {
  color: var(--input-asterisk-color, var(--mantine-color-error));
}

.m_8f816625,
.m_fe47ce59 {
  word-wrap: break-word;
  line-height: 1.2;
  display: block;
  margin: 0;
  padding: 0;
}

.m_8f816625 {
  color: var(--mantine-color-error);
  font-size: var(--input-error-size, calc(var(--mantine-font-size-sm) - (0.125rem * var(--mantine-scale))));
  font-size: var(--input-error-size, calc(var(--mantine-font-size-sm) - calc(0.125rem * var(--mantine-scale))));
}

.m_fe47ce59 {
  color: var(--mantine-color-dimmed);
  font-size: var(--input-description-size, calc(var(--mantine-font-size-sm) - (0.125rem * var(--mantine-scale))));
  font-size: var(--input-description-size, calc(var(--mantine-font-size-sm) - calc(0.125rem * var(--mantine-scale))));
}

.m_88b62a41 {
  --combobox-padding: 4px;
  padding: 4px;
  padding: var(--combobox-padding);
}

  .m_88b62a41:has([data-mantine-scrollbar]) {
    padding-right: 0;
  }

  .m_88b62a41:has([data-mantine-scrollbar]) .m_985517d8 {
      max-width: calc(100% + var(--combobox-padding));
    }

  .m_88b62a41[data-hidden] {
    display: none;
  }

/* Variables must be both on dropdown and options to support usage of Combobox.Options without Combobox.Dropdown */
.m_88b62a41,
.m_b2821a6e {
  --combobox-option-padding-xs: 4px 8px;
  --combobox-option-padding-sm: 6px 10px;
  --combobox-option-padding-md: 8px 12px;
  --combobox-option-padding-lg: 10px 16px;
  --combobox-option-padding-xl: 14px 20px;
  --combobox-option-padding: var(--combobox-option-padding-sm);
}

.m_92253aa5 {
  padding: var(--combobox-option-padding);
  font-size: var(--combobox-option-fz, var(--mantine-font-size-sm));
  border-radius: var(--mantine-radius-default);
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  word-break: break-word;
}

.m_92253aa5:where([data-combobox-selected]) {
    background-color: var(--mantine-primary-color-filled);
    color: var(--mantine-color-white);
  }

.m_92253aa5:where([data-combobox-disabled]) {
    cursor: not-allowed;
    opacity: 0.35;
  }

@media (hover: hover) {
      :where([data-mantine-color-scheme='light']) .m_92253aa5:hover:where(:not([data-combobox-selected]):not([data-combobox-disabled])) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_92253aa5:hover:where(:not([data-combobox-selected]):not([data-combobox-disabled])) {
        background-color: var(--mantine-color-dark-7);
  }
}

@media (hover: none) {
      :where([data-mantine-color-scheme='light']) .m_92253aa5:active:where(:not([data-combobox-selected]):not([data-combobox-disabled])) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_92253aa5:active:where(:not([data-combobox-selected]):not([data-combobox-disabled])) {
        background-color: var(--mantine-color-dark-7);
  }
}

.m_985517d8 {
  margin-left: calc(var(--combobox-padding) * -1);
  margin-right: calc(var(--combobox-padding) * -1);
  margin-top: calc(var(--combobox-padding) * -1);
  width: calc(100% + var(--combobox-padding) * 2);
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: var(--combobox-padding);
  position: relative;
}

:where([data-mantine-color-scheme='light']) .m_985517d8, :where([data-mantine-color-scheme='light']) .m_985517d8:focus {
      border-color: var(--mantine-color-gray-2);
}

:where([data-mantine-color-scheme='dark']) .m_985517d8, :where([data-mantine-color-scheme='dark']) .m_985517d8:focus {
      border-color: var(--mantine-color-dark-4);
}

:where([data-mantine-color-scheme='light']) .m_985517d8 {
    background-color: var(--mantine-color-white);
}

:where([data-mantine-color-scheme='dark']) .m_985517d8 {
    background-color: var(--mantine-color-dark-7);
}

.m_2530cd1d {
  font-size: var(--combobox-option-fz, var(--mantine-font-size-sm));
  text-align: center;
  padding: var(--combobox-option-padding);
  color: var(--mantine-color-dimmed);
}

.m_858f94bd,
.m_82b967cb {
  font-size: var(--combobox-option-fz, var(--mantine-font-size-sm));
  border: 0 solid transparent;
  margin-left: calc(var(--combobox-padding) * -1);
  margin-right: calc(var(--combobox-padding) * -1);
  padding: var(--combobox-option-padding);
}

:where([data-mantine-color-scheme='light']) .m_858f94bd, :where([data-mantine-color-scheme='light']) .m_82b967cb {
    border-color: var(--mantine-color-gray-2);
}

:where([data-mantine-color-scheme='dark']) .m_858f94bd, :where([data-mantine-color-scheme='dark']) .m_82b967cb {
    border-color: var(--mantine-color-dark-4);
}

.m_82b967cb {
  border-top-width: 1px;
  margin-top: var(--combobox-padding);
  margin-bottom: calc(var(--combobox-padding) * -1);
}

.m_858f94bd {
  border-bottom-width: 1px;
  margin-bottom: var(--combobox-padding);
  margin-top: calc(var(--combobox-padding) * -1);
}

.m_254f3e4f:has(.m_2bb2e9e5:only-child) {
    display: none;
  }

.m_2bb2e9e5 {
  color: var(--mantine-color-dimmed);
  font-size: calc(var(--combobox-option-fz, var(--mantine-font-size-sm)) * 0.85);
  padding: var(--combobox-option-padding);
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
}

.m_2bb2e9e5::after {
    content: '';
    flex: 1 1;
    left: 0;
    right: 0;
    height: 1px;
    margin-left: var(--mantine-spacing-xs);
  }

:where([data-mantine-color-scheme='light']) .m_2bb2e9e5::after {
      background-color: var(--mantine-color-gray-2);
}

:where([data-mantine-color-scheme='dark']) .m_2bb2e9e5::after {
      background-color: var(--mantine-color-dark-4);
}

.m_2bb2e9e5:only-child {
    display: none;
  }

/* ------- Combobox.Chevron ------- */
.m_2943220b {
  --combobox-chevron-size-xs: 14px;
  --combobox-chevron-size-sm: 18px;
  --combobox-chevron-size-md: 20px;
  --combobox-chevron-size-lg: 24px;
  --combobox-chevron-size-xl: 28px;
  --combobox-chevron-size: var(--combobox-chevron-size-sm);

  width: 18px;

  width: var(--combobox-chevron-size);
  height: 18px;
  height: var(--combobox-chevron-size);
}
:where([data-mantine-color-scheme='light']) .m_2943220b {
    color: var(--mantine-color-gray-6);
}
:where([data-mantine-color-scheme='dark']) .m_2943220b {
    color: var(--mantine-color-dark-3);
}
.m_2943220b:where([data-error]) {
    color: var(--mantine-color-error);
  }

/* ------- OptionsDropdown ------- */
.m_390b5f4 {
  display: flex;
  align-items: center;
  gap: 8px;
}
.m_390b5f4:where([data-reverse]) {
    justify-content: space-between;
  }

.m_8ee53fc2 {
  opacity: 0.4;
  width: 0.8em;
  min-width: 0.8em;
  height: 0.8em;
}

:where([data-combobox-selected]) .m_8ee53fc2 {
    opacity: 1;
  }

.m_7cda1cd6 {
  --pill-fz-xs: 10px;
  --pill-fz-sm: 12px;
  --pill-fz-md: 14px;
  --pill-fz-lg: 16px;
  --pill-fz-xl: 18px;

  --pill-height-xs: 18px;
  --pill-height-sm: 22px;
  --pill-height-md: 25px;
  --pill-height-lg: 28px;
  --pill-height-xl: 32px;

  --pill-fz: var(--pill-fz-sm);
  --pill-height: var(--pill-height-sm);

  font-size: 12px;

  font-size: var(--pill-fz);
  flex: 0 1;
  height: 22px;
  height: var(--pill-height);
  padding-left: 0.8em;
  padding-right: 0.8em;
  display: inline-flex;
  align-items: center;
  border-radius: 1000rem;
  border-radius: var(--pill-radius, 1000rem);
  line-height: 1;
  white-space: nowrap;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  max-width: 100%;
}

  :where([data-mantine-color-scheme='dark']) .m_7cda1cd6 {
    background-color: var(--mantine-color-dark-7);
    color: var(--mantine-color-dark-0);
}

  :where([data-mantine-color-scheme='light']) .m_7cda1cd6 {
    color: var(--mantine-color-black);
}

  .m_7cda1cd6:where([data-with-remove]:not(:has(button:disabled))) {
    padding-right: 0;
  }

  .m_7cda1cd6:where([data-disabled], :has(button:disabled)) {
    cursor: not-allowed;
  }

:where([data-mantine-color-scheme='light']) .m_44da308b {
    background-color: var(--mantine-color-gray-1);
}

:where([data-mantine-color-scheme='light']) .m_44da308b:where([data-disabled], :has(button:disabled)) {
      background-color: var(--mantine-color-gray-3);
    }

:where([data-mantine-color-scheme='light']) .m_e3a01f8 {
    background-color: var(--mantine-color-white);
}

:where([data-mantine-color-scheme='light']) .m_e3a01f8:where([data-disabled], :has(button:disabled)) {
      background-color: var(--mantine-color-gray-3);
    }

.m_1e0e6180 {
  cursor: inherit;
  overflow: hidden;
  height: 100%;
  line-height: var(--pill-height);
  text-overflow: ellipsis;
}

.m_ae386778 {
  color: inherit;
  font-size: inherit;
  height: 100%;
  min-height: 0;
  min-height: initial;
  min-width: 2em;
  width: auto;
  width: initial;
  border-radius: 0;
  padding-left: 0.1em;
  padding-right: 0.3em;
  flex: 0 1;
  border-bottom-right-radius: 50%;
  border-bottom-right-radius: var(--pill-radius, 50%);
  border-top-right-radius: 50%;
  border-top-right-radius: var(--pill-radius, 50%);
}

.m_7cda1cd6[data-disabled] > .m_ae386778,
  .m_ae386778:disabled {
    display: none;
    background-color: transparent;
    width: 0.8em;
    min-width: 0.8em;
    padding: 0;
    cursor: not-allowed;
  }

.m_7cda1cd6[data-disabled] > .m_ae386778 > svg, .m_ae386778:disabled > svg {
      display: none;
    }

.m_ae386778 > svg {
    pointer-events: none;
  }

.m_1dcfd90b {
  --pg-gap-xs: 6px;
  --pg-gap-sm: 8px;
  --pg-gap-md: 10px;
  --pg-gap-lg: 12px;
  --pg-gap-xl: 12px;
  --pg-gap: var(--pg-gap-sm);

  display: flex;
  align-items: center;
  gap: 8px;
  gap: var(--pg-gap);
  flex-wrap: wrap;
}

.m_45c4369d {
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  min-width: 100px;
  flex: 1 1;
  border: 0;
  font-size: inherit;
  height: 1.6em;
  color: inherit;
  padding: 0;
}

  .m_45c4369d::-moz-placeholder {
    color: var(--input-placeholder-color);
    opacity: 1;
  }

  .m_45c4369d::placeholder {
    color: var(--input-placeholder-color);
    opacity: 1;
  }

  .m_45c4369d:where([data-type='hidden'], [data-type='auto']) {
    height: 1px;
    width: 1px;
    top: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    opacity: 0;
  }

  .m_45c4369d:focus {
    outline: none;
  }

  .m_45c4369d:where([data-type='auto']:focus) {
    height: 1.6em;
    visibility: visible;
    opacity: 1;
    position: static;
  }

  .m_45c4369d:where([data-pointer]:not([data-disabled]):not(:disabled)) {
    cursor: pointer;
  }

  .m_45c4369d:where([data-disabled], :disabled) {
    cursor: not-allowed;
  }

.m_38a85659 {
  position: absolute;
  border: 1px solid var(--popover-border-color);
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
  box-shadow: none;
  box-shadow: var(--popover-shadow, none);
  border-radius: var(--popover-radius, var(--mantine-radius-default));
}

  .m_38a85659:where([data-fixed]) {
    position: fixed;
  }

  .m_38a85659:focus {
    outline: none;
  }

  :where([data-mantine-color-scheme='light']) .m_38a85659 {
    --popover-border-color: var(--mantine-color-gray-2);
    background-color: var(--mantine-color-white);
}

  :where([data-mantine-color-scheme='dark']) .m_38a85659 {
    --popover-border-color: var(--mantine-color-dark-4);
    background-color: var(--mantine-color-dark-6);
}

.m_a31dc6c1 {
  background-color: inherit;
  border: 1px solid var(--popover-border-color);
  z-index: 1;
}

.m_d57069b5 {
  --scrollarea-scrollbar-size: 12px;

  position: relative;
  overflow: hidden;
}

.m_c0783ff9 {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
}

.m_c0783ff9::-webkit-scrollbar {
    display: none;
  }

.m_c0783ff9:where([data-scrollbars='xy'], [data-scrollbars='y']):where([data-offset-scrollbars='xy'], [data-offset-scrollbars='y']) {
      padding-right: var(--scrollarea-scrollbar-size);
      padding-left: 0;
      padding-left: initial;
    }

.m_c0783ff9:where([data-scrollbars='xy'], [data-scrollbars='x']):where([data-offset-scrollbars='xy'], [data-offset-scrollbars='x']) {
      padding-bottom: var(--scrollarea-scrollbar-size);
    }

.m_f8f631dd {
  min-width: 100%;
  display: table;
}

.m_c44ba933 {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  touch-action: none;
  box-sizing: border-box;
  transition:
    background-color 150ms ease, opacity 150ms ease;

  padding: calc(var(--scrollarea-scrollbar-size) / 5);
  display: flex;
  background-color: transparent;
  flex-direction: row;
}

@media (hover: hover) {
    :where([data-mantine-color-scheme='light']) .m_c44ba933:hover {
      background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='light']) .m_c44ba933:hover > .m_d8b5e363 {
        background-color: rgba(0, 0, 0, 0.5);
      }

    :where([data-mantine-color-scheme='dark']) .m_c44ba933:hover {
      background-color: var(--mantine-color-dark-8);
  }

      :where([data-mantine-color-scheme='dark']) .m_c44ba933:hover > .m_d8b5e363 {
        background-color: rgba(255, 255, 255, 0.5);
      }
}

@media (hover: none) {
    :where([data-mantine-color-scheme='light']) .m_c44ba933:active {
      background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='light']) .m_c44ba933:active > .m_d8b5e363 {
        background-color: rgba(0, 0, 0, 0.5);
      }

    :where([data-mantine-color-scheme='dark']) .m_c44ba933:active {
      background-color: var(--mantine-color-dark-8);
  }

      :where([data-mantine-color-scheme='dark']) .m_c44ba933:active > .m_d8b5e363 {
        background-color: rgba(255, 255, 255, 0.5);
      }
}

.m_c44ba933:where([data-hidden], [data-state='hidden']) {
    display: none;
  }

.m_c44ba933:where([data-orientation='vertical']) {
    width: var(--scrollarea-scrollbar-size);
    top: 0;
    bottom: var(--sa-corner-width);
    right: 0;
  }

.m_c44ba933:where([data-orientation='horizontal']) {
    height: var(--scrollarea-scrollbar-size);
    flex-direction: column;
    bottom: 0;
    left: 0;
    right: var(--sa-corner-width);
  }

.m_d8b5e363 {
  flex: 1 1;
  border-radius: var(--scrollarea-scrollbar-size);
  position: relative;
  transition: background-color 150ms ease;
  overflow: hidden;
  opacity: var(--thumb-opacity);
}

.m_d8b5e363::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
  }

:where([data-mantine-color-scheme='light']) .m_d8b5e363 {
    background-color: rgba(0, 0, 0, 0.4);
}

:where([data-mantine-color-scheme='dark']) .m_d8b5e363 {
    background-color: rgba(255, 255, 255, 0.4);
}

.m_21657268 {
  position: absolute;
  opacity: 0;
  transition: opacity 150ms ease;
  display: block;
  right: 0;
  bottom: 0;
}

:where([data-mantine-color-scheme='light']) .m_21657268 {
    background-color: var(--mantine-color-gray-0);
}

:where([data-mantine-color-scheme='dark']) .m_21657268 {
    background-color: var(--mantine-color-dark-8);
}

.m_21657268:where([data-hovered]) {
    opacity: 1;
  }

.m_21657268:where([data-hidden]) {
    display: none;
  }

.m_8bffd616 {
  display: flex;
}

.m_66836ed3 {
  --alert-radius: var(--mantine-radius-default);
  --alert-bg: var(--mantine-primary-color-light);
  --alert-bd: 1px solid transparent;
  --alert-color: var(--mantine-primary-color-light-color);

  padding: var(--mantine-spacing-md) var(--mantine-spacing-md);
  border-radius: var(--alert-radius);
  position: relative;
  overflow: hidden;
  background-color: var(--alert-bg);
  border: 1px solid transparent;
  border: var(--alert-bd);
  color: var(--alert-color);
}

.m_a5d60502 {
  display: flex;
}

.m_667c2793 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: var(--mantine-spacing-xs);
}

.m_6a03f287 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
}

.m_6a03f287:where([data-with-close-button]) {
    padding-right: var(--mantine-spacing-md);
  }

.m_698f4f23 {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.m_667f2a6a {
  line-height: 1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: var(--mantine-spacing-md);
  margin-top: 1px;
}

.m_7fa78076 {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: var(--mantine-font-size-sm);
}

:where([data-mantine-color-scheme='light']) .m_7fa78076 {
    color: var(--mantine-color-black);
}

:where([data-mantine-color-scheme='dark']) .m_7fa78076 {
    color: var(--mantine-color-white);
}

.m_7fa78076:where([data-variant='filled']) {
    color: var(--alert-color);
  }

.m_7fa78076:where([data-variant='white']) {
    color: var(--mantine-color-black);
  }

.m_87f54839 {
  width: 20px;
  height: 20px;
  color: var(--alert-color);
}

.m_86a44da5 {
  --cb-size-xs: 18px;
  --cb-size-sm: 22px;
  --cb-size-md: 28px;
  --cb-size-lg: 34px;
  --cb-size-xl: 44px;

  --cb-size: var(--cb-size-md);
  --cb-icon-size: 70%;
  --cb-radius: var(--mantine-radius-default);

  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;

  width: 28px;

  width: var(--cb-size);
  height: 28px;
  height: var(--cb-size);
  min-width: 28px;
  min-width: var(--cb-size);
  min-height: 28px;
  min-height: var(--cb-size);
  border-radius: var(--cb-radius);
}

  :where([data-mantine-color-scheme='light']) .m_86a44da5 {
    color: var(--mantine-color-gray-7);
}

  :where([data-mantine-color-scheme='dark']) .m_86a44da5 {
    color: var(--mantine-color-dark-1);
}

  .m_86a44da5[data-disabled],
  .m_86a44da5:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

@media (hover: hover) {
    :where([data-mantine-color-scheme='light']) .m_220c80f2:where(:not([data-disabled]):not(:disabled)):hover {
      background-color: var(--mantine-color-gray-0);
  }

    :where([data-mantine-color-scheme='dark']) .m_220c80f2:where(:not([data-disabled]):not(:disabled)):hover {
      background-color: var(--mantine-color-dark-6);
  }
}

@media (hover: none) {
    :where([data-mantine-color-scheme='light']) .m_220c80f2:where(:not([data-disabled]):not(:disabled)):active {
      background-color: var(--mantine-color-gray-0);
  }

    :where([data-mantine-color-scheme='dark']) .m_220c80f2:where(:not([data-disabled]):not(:disabled)):active {
      background-color: var(--mantine-color-dark-6);
  }
}

.m_abbac491 {
  --list-fz: var(--mantine-font-size-md);
  --list-lh: var(--mantine-line-height-md);

  list-style-position: inside;
  font-size: var(--list-fz);
  line-height: var(--list-lh);
  margin: 0;
  padding: 0;
}

  .m_abbac491:where([data-with-padding]) {
    padding-left: var(--mantine-spacing-md);
  }

.m_abb6bec2 {
  white-space: nowrap;
  line-height: var(--list-lh);
}

.m_abb6bec2:where([data-with-icon]) {
    list-style: none;
  }

.m_abb6bec2:where([data-with-icon]) .m_75cd9f71 {
      --li-direction: row;
      --li-align: center;
    }

.m_abb6bec2:where(:not(:first-of-type)) {
    margin-top: 0;
    margin-top: var(--list-spacing, 0);
  }

.m_abb6bec2:where([data-centered]) {
    line-height: 1;
  }

.m_75cd9f71 {
  display: inline-flex;
  flex-direction: column;
  flex-direction: var(--li-direction, column);
  align-items: flex-start;
  align-items: var(--li-align, flex-start);
  white-space: normal;
}

.m_60f83e5b {
  display: inline-block;
  vertical-align: middle;
  margin-right: var(--mantine-spacing-sm);
}

.m_43657ece {
  --offset: calc(var(--tl-bullet-size) / 2 + var(--tl-line-width) / 2);
  --tl-bullet-size: 20px;
  --tl-line-width: 4px;
  --tl-radius: 1000px;
  --tl-color: var(--mantine-primary-color-filled);
}

  .m_43657ece:where([data-align='left']) {
    padding-left: var(--offset);
  }

  .m_43657ece:where([data-align='right']) {
    padding-right: var(--offset);
  }

.m_2ebe8099 {
  font-weight: 500;
  line-height: 1;
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
}

.m_436178ff {
  --item-border: var(--tl-line-width) var(--tli-border-style, solid) var(--item-border-color);

  position: relative;
  color: var(--mantine-color-text);
}

.m_436178ff::before {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    left: var(--timeline-line-left, 0);
    right: 0;
    right: var(--timeline-line-right, 0);
    bottom: calc(var(--mantine-spacing-xl) * -1);
    border-left: var(--item-border);
    display: none;
    display: var(--timeline-line-display, none);
  }

.m_43657ece[data-align='left'] .m_436178ff::before {
      --timeline-line-left: calc(var(--tl-line-width) * -1);
      --timeline-line-right: auto;
    }

[dir="rtl"] .m_43657ece[data-align='left'] .m_436178ff::before {
        --timeline-line-left: auto;
        --timeline-line-right: calc(var(--tl-line-width) * -1);
}

.m_43657ece[data-align='right'] .m_436178ff::before {
      --timeline-line-left: auto;
      --timeline-line-right: calc(var(--tl-line-width) * -1);
    }

[dir="rtl"] .m_43657ece[data-align='right'] .m_436178ff::before {
        --timeline-line-left: calc(var(--tl-line-width) * -1);
        --timeline-line-right: auto;
}

.m_43657ece:where([data-align='left']) .m_436178ff {
    padding-left: var(--offset);
    text-align: left;
  }

.m_43657ece:where([data-align='right']) .m_436178ff {
    padding-right: var(--offset);
    text-align: right;
  }

:where([data-mantine-color-scheme='light']) .m_436178ff {
    --item-border-color: var(--mantine-color-gray-3);
}

:where([data-mantine-color-scheme='dark']) .m_436178ff {
    --item-border-color: var(--mantine-color-dark-4);
}

.m_436178ff:where([data-line-active])::before {
      border-color: var(--tli-color, var(--tl-color));
    }

.m_436178ff:where(:not(:last-of-type)) {
    --timeline-line-display: block;
  }

.m_436178ff:where(:not(:first-of-type)) {
    margin-top: var(--mantine-spacing-xl);
  }

.m_8affcee1 {
  width: var(--tl-bullet-size);
  height: var(--tl-bullet-size);
  border-radius: var(--tli-radius, var(--tl-radius));
  border: var(--tl-line-width) solid;
  background-color: var(--mantine-color-body);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mantine-color-text);
}

:where([data-mantine-color-scheme='light']) .m_8affcee1 {
    border-color: var(--mantine-color-gray-3);
}

:where([data-mantine-color-scheme='dark']) .m_8affcee1 {
    border-color: var(--mantine-color-dark-4);
}

.m_43657ece:where([data-align='left']) .m_8affcee1 {
    left: calc((var(--tl-bullet-size) / 2 + var(--tl-line-width) / 2) * -1);
    right: auto;
  }

:where([dir="rtl"]) .m_43657ece:where([data-align='left']) .m_8affcee1 {
      left: auto;
      right: calc((var(--tl-bullet-size) / 2 + var(--tl-line-width) / 2) * -1);
}

.m_43657ece:where([data-align='right']) .m_8affcee1 {
    left: auto;
    right: calc((var(--tl-bullet-size) / 2 + var(--tl-line-width) / 2) * -1);
  }

:where([dir="rtl"]) .m_43657ece:where([data-align='right']) .m_8affcee1 {
      left: calc((var(--tl-bullet-size) / 2 + var(--tl-line-width) / 2) * -1);
      right: auto;
}

.m_8affcee1:where([data-with-child]) {
    border-width: var(--tl-line-width);
  }

:where([data-mantine-color-scheme='light']) .m_8affcee1:where([data-with-child]) {
      background-color: var(--mantine-color-gray-3);
}

:where([data-mantine-color-scheme='dark']) .m_8affcee1:where([data-with-child]) {
      background-color: var(--mantine-color-dark-4);
}

.m_8affcee1:where([data-active]) {
    border-color: var(--tli-color, var(--tl-color));
    background-color: var(--mantine-color-white);
    color: var(--tl-icon-color, var(--mantine-color-white));
  }

.m_8affcee1:where([data-active]):where([data-with-child]) {
      background-color: var(--tli-color, var(--tl-color));
      color: var(--tl-icon-color, var(--mantine-color-white));
    }

.m_43657ece:where([data-align='left']) .m_540e8f41 {
    padding-left: var(--offset);
    text-align: left;
  }

:where([dir="rtl"]) .m_43657ece:where([data-align='left']) .m_540e8f41 {
      text-align: right;
}

.m_43657ece:where([data-align='right']) .m_540e8f41 {
    padding-right: var(--offset);
    text-align: right;
  }

:where([dir="rtl"]) .m_43657ece:where([data-align='right']) .m_540e8f41 {
      text-align: left;
}

